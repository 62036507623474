var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "payment-summary" }, [
    _c("div", { staticClass: "payment-header" }, [
      _c("div", { staticClass: "row" }, [
        _c("p", { staticClass: "bold" }, [_vm._v(_vm._s(_vm.header))]),
        _c("p", [_vm._v(_vm._s(_vm.reference))]),
      ]),
      _vm.isCustoms
        ? _c("div", { staticClass: "row" }, [
            _c("p", { staticClass: "bold" }, [
              _vm._v(_vm._s(_vm.customsHeader)),
            ]),
            _c("p", [_vm._v(_vm._s(_vm.customsReference))]),
          ])
        : _vm._e(),
    ]),
    _c(
      "table",
      { class: { customs: _vm.isCustoms } },
      _vm._l(_vm.sections, function (section, i) {
        return _c(
          "tbody",
          { key: i },
          [
            _vm.sections.length > 1
              ? _c("tr", { attrs: { colspan: "2" } }, [
                  _c("td", { staticClass: "header" }, [
                    _vm._v(_vm._s(_vm.$t("AMOUNT_TO", { name: section.name }))),
                  ]),
                ])
              : _vm._e(),
            _vm._l(section.rows, function (row, j) {
              return _c("tr", { key: j }, [
                _c("td", [_vm._v(_vm._s(_vm.$t(row.type)))]),
                row.type !== "HANDLING_FEE_TOTAL"
                  ? _c("td", [
                      _vm._v(" " + _vm._s(_vm.format(row.amount)) + " "),
                    ])
                  : _vm._e(),
                row.type === "HANDLING_FEE_TOTAL"
                  ? _c("td", [_vm._v(_vm._s(row.amount))])
                  : _vm._e(),
              ])
            }),
            _vm.sections.length > 1
              ? _c("tr", { staticClass: "footer" }, [
                  section.name
                    ? _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("AMOUNT_TO_TOTAL", { name: section.name })
                          )
                        ),
                      ])
                    : _c("td"),
                  _c("td", [_vm._v(_vm._s(_vm.sectionTotal(section)))]),
                ])
              : _vm._e(),
          ],
          2
        )
      }),
      0
    ),
    _c("div", { staticClass: "payment-sum row" }, [
      _c("p", { staticClass: "bold" }, [
        _vm._v(
          _vm._s(
            _vm.isCostPreliminary
              ? _vm.$t("PRELIMINARY_AMOUNT_TO_PAY")
              : _vm.$t("AMOUNT_TO_PAY")
          )
        ),
      ]),
      _c("p", { staticClass: "total" }, [
        _c("strong", [_vm._v(_vm._s(_vm.total.amount))]),
        _c("span", { staticClass: "currency" }, [
          _vm._v(_vm._s(_vm.total.currency)),
        ]),
        _vm.isCostPreliminary
          ? _c("span", { staticClass: "asterisk" }, [
              _vm._v(_vm._s(_vm.$t("ASTERISK"))),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }