<script>
// TODO: Form validation
export default {
  data() {
    return {
      displayErrors: false,
      form: [
        {
          name: 'itemId',
          isValid: (val) => val.length > 0,
          placeholder: 'INVOICE_NUMBER',
          value: '',
          errorMessage: 'INVALID_INPUT_INVOICE_NUMBER',
        },
        {
          name: 'code',
          isValid: (val) => val.length === 4,
          placeholder: 'PINCODE',
          value: '',
          errorMessage: 'INVALID_INPUT_PINCODE',
        },
      ],
    };
  },

  created() {
    // Submit with return-key
    window.addEventListener('keyup', (event) => {
      if (event.keyCode === 13) {
        this.submit();
      }
    });

    // Tries to prefill all fields from the query params
    Object.keys(this.$route.query).forEach((param) => {
      const formInput = this.form.find((i) => i.name === param);
      if (formInput) {
        formInput.value = this.$route.query[param];
      }
    });

    if (this.form[0].value && this.form[1].value) {
      this.submit();
    }
  },

  methods: {
    submit() {
      const invalidInputs = this.form.filter((i) => !i.isValid(i.value));
      if (invalidInputs.length > 0) {
        this.displayErrors = true;
      } else {
        this.$emit('onSearch', {
          itemId: this.form[0].value,
          code: this.form[1].value,
        });
      }
    },
  },
};
</script>

<template>
  <div>
    <p class="description">{{ $t('DK_SEARCH_DESCRIPTION') }}</p>

    <section class="form">
      <pn-input
        class='pn-vat-input'
        v-for="(input, i) in form"
        :key="i"
        :label="$t(input.placeholder)"
        :error="(!input.isValid(input.value) && displayErrors) ? $t(input.errorMessage) : ''"
        type="text"
        :value="input.value"
        @input="input.value = $event.target.value"
      />
    </section>

    <p>{{ $t('DK_SEARCH_EXPLANATION') }}</p>

    <pn-button
      class='pn-vat-button'
      @click="submit()"
    >
      {{ $t('SEARCH_BUTTON') }}
    </pn-button>
  </div>
</template>

<style lang="scss" scoped>
  p {
    color: $gray700;
    margin: 1.6rem 0 0;

    &.description {
      color: $gray900;
    }
  }
</style>
