<script>

export default ({
  props: {
    title: {
      type: String,
      required: true,
    },

    description: {
      type: String,
      required: true,
    },
  },
});
</script>

<template>
  <div class="error-msg-container">
    <div class="error-msg">
      <span class="error-title">{{ $t(title) }}</span>
      <span class="error-description">{{ $t(description) }}</span>
    </div>
  </div>
</template>

<style lang="scss">
  .error-msg {
    padding: 1.25em;
    margin-bottom: 1.6rem;
    background-color: $coral50;

    span {
      margin: 0;
      color: $coral700;
    }

    .error-title {
      font-size: 2.0rem;
      font-weight: $bold;
      line-height: 2.4rem;
    }

    .error-description {
      display: block;
      margin-top: .8rem;
    }
  }

  @media screen and (max-width: 464px) {
    .error-msg-container {
      margin: 0;
      padding: 0;
    }
  }
</style>
