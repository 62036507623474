<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    steps: {
      type: Array,
      required: true,
    },

    showStepper: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentStep: null,
    };
  },

  mounted() {
    const initialStep = this.steps.find((s) => s.component === this.$route.name);

    if (initialStep) {
      this.currentStep = this.steps.indexOf(initialStep);
    } else {
      this.currentStep = 0;
    }
  },

  computed: {
    ...mapGetters('topbar', ['market']),

    ...mapGetters('shipment', ['item']),

    current() {
      return this.steps[this.currentStep];
    },

    activeStep() {
      return this.steps.find((s) => this.isActive(s));
    },

    isInvisible() {
      return this.market === 'SE' && (this.isActive(this.steps[0]) || !this.item?.customsId);
    },

    meta() {
      let meta;
      const { matched } = this.$route;
      // Reverse to get most specific match first
      [...matched].reverse().forEach((m) => {
        const matchMeta = this.findMeta(m);

        if (Object.keys(matchMeta).length) {
          meta = matchMeta;
        }
      });

      return meta;
    },
  },

  methods: {
    updateStep(i) {
      this.currentStep = i;
    },

    findMeta(route) {
      const hasMeta = route.meta;

      if (hasMeta) {
        return route.meta;
      }

      if (route.parent) {
        return this.findMeta(route.parent);
      }

      return null;
    },

    isActive(step) {
      return this.$route.name === step.component || this.$route.meta.step === step.component;
    },

    iconColor(isActive) {
      return isActive ? 'gray900' : 'gray700';
    },

    isVisited(i) {
      return i < this.steps.indexOf(this.activeStep);
    },
  },
};
</script>

<template>
  <div v-if="currentStep !== null">
    <div class="header" :style="{ 'background-color': meta.headerColor }" v-if="meta">
      <div class="image-container">
        <img alt="customs illustration" :src="require(`../assets/${meta.header}`)">
      </div>
    </div>

    <div class="steps" v-if="showStepper" :class="{ invisible: isInvisible }">
      <span v-for="(step, i) in steps" :key="i" :class="{ 'router-link-active': isActive(step), 'router-link-visited': isVisited(i) }">
        <pn-icon
          :symbol="step.icon"
          :color="iconColor(isActive(step))"
          :small="false"
          solid
        />
        <p class="desktop-only">{{ $t(step.name) }}</p>
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .header {
    height: 280px;
    max-width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    @media #{$phone} {
      height: 164px;
    }

    .image-container {
      height: 85%;
      text-align: center;
      position: relative;
      overflow: visible;;

      img {
        position: absolute;
        left: 50%;
        height: 100%;
        bottom: 0;
        transform: translate(-50%,0);
      }
    }
  }

  .steps {
    width: 100%;
    display: flex;
    padding: 0 .8rem;
    justify-content: center;
    box-sizing: border-box;

    @media #{$phone} {
      padding: 1.2rem 1.6rem .4rem;
      margin-top: 0.5em;
    }

    &.invisible {
      visibility: hidden;
      height: 0;
    }

    span {
      flex: 0 185px;
      margin-right: 4px;
      border-bottom: 4px solid $gray200;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 8rem;
      padding: .8rem;
      color: $gray700;

      @media #{$phone} {
        height: 2.8rem;
      }

      pn-icon {
        margin: 0 auto;
      }

      p {
        margin: 0;
        text-align: center;
        color: inherit;
        font-size: 1.4rem;
        font-weight: $light;
      }

      &:last-of-type {
        margin-right: 0;
      }

      &.router-link {
        &-active {
          border-bottom: 4px solid $blue700;

          p {
            color: $gray900;
            font-weight: $medium;
          }
        }
        &-visited {
          border-bottom: 4px solid $blue700;
        }
      }
    }
  }

  .content-container {
    max-width: 464px;
    margin: auto;
  }
</style>
