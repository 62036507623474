var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.topbarName === "market-web"
        ? [
            _c("topbar-market-web", {
              on: {
                onMarketChanged: _vm.onMarketChanged,
                onLanguageChanged: _vm.onLanguageChanged,
                onLoaded: _vm.onTopbarLoaded,
              },
            }),
          ]
        : _vm._e(),
      _vm.topbarName === "portal"
        ? [
            _c("topbar-portal", {
              on: {
                onMarketChanged: _vm.onMarketChanged,
                onLanguageChanged: _vm.onLanguageChanged,
                onLoaded: _vm.onTopbarLoaded,
              },
            }),
          ]
        : _vm._e(),
      _vm.isInitialized
        ? _c(
            "div",
            { staticClass: "main-container" },
            [
              _c("sidebar", { attrs: { id: "sidebar" } }),
              _c(
                "div",
                { staticClass: "main" },
                [
                  _vm.market === "SE"
                    ? _c("stepper", {
                        key: "se-market",
                        attrs: { steps: _vm.steps("SE"), "show-stepper": true },
                      })
                    : _vm._e(),
                  _vm.market === "DK"
                    ? _c("stepper", {
                        key: "dk-market",
                        attrs: { steps: _vm.steps("DK"), "show-stepper": true },
                      })
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isLoading,
                          expression: "!isLoading",
                        },
                      ],
                    },
                    [
                      _c("router-view", { attrs: { id: "main-view" } }),
                      _vm.topbarName === "market-web"
                        ? [
                            _c("footer-market-web", {
                              staticClass: "footer-market-web no-print",
                              on: {
                                onMarketChanged: _vm.onMarketChanged,
                                onLanguageChanged: _vm.onLanguageChanged,
                                onLoaded: _vm.onTopbarLoaded,
                              },
                            }),
                          ]
                        : _vm._e(),
                      _vm.topbarName === "portal"
                        ? [
                            _c("pn-footer", {
                              staticClass: "no-print",
                              attrs: { id: "footer" },
                            }),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isLoading,
                          expression: "isLoading",
                        },
                      ],
                      attrs: { id: "loader" },
                    },
                    [_c("loader")],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.market === "SE"
        ? _c("a", { attrs: { href: "//postnord.humany.net/tull-och-moms-se" } })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }