var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "footer-container", attrs: { id: "footer-market-web" } },
    [
      _vm.market && _vm.language
        ? [
            _c("pn-marketweb-sitefooter", {
              attrs: {
                endpoint: _vm.ENDPOINT,
                market: _vm.market.toLowerCase(),
                language: _vm.language,
                theme: "dotcom",
                backgroundcolor: "gray",
              },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }