<script>
export default ({
  mounted() {
    this.$nextTick(() => {
      // Load remote code
      const script = document.createElement('script');
      script.setAttribute('src', process.env.VUE_APP_TOPBAR_URL);
      (this.$refs['topbar-container']).appendChild(script);

      // Emit when loaded
      script.onload = () => this.onLoaded();

      // Eventhooks
      this.$refs['topbar-container'].addEventListener('changeLanguage', this.onLanguageChanged);
      this.$refs['topbar-container'].addEventListener('changeMarkets', this.onMarketChanged);
    });
  },

  methods: {
    onLoaded() {
      this.$emit('onLoaded');
    },

    onMarketChanged(e) {
      this.$emit('onMarketChanged', e.detail.markets);
    },

    onLanguageChanged(e) {
      this.$emit('onLanguageChanged', e.detail.language);
    },
  },
});
</script>

<template>
  <div id="topbar-portal" ref="topbar-container">
    <pn-topbar ref="topbar" v-pre />
  </div>
</template>

<style lang="scss">

</style>
