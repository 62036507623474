var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("h1", [
      _vm._v(" " + _vm._s(_vm.$t("CONFIRMATION_PREPAID_VAT_HEADER")) + " "),
    ]),
    _c("section", { staticClass: "shipment-information" }, [
      _c("div", { staticClass: "row" }, [
        _c("p", { staticClass: "bold" }, [
          _vm._v(_vm._s(_vm.$t("SHIPMENT_ID"))),
        ]),
        _c("p", [_vm._v(_vm._s(_vm.item.itemId))]),
      ]),
    ]),
    _c("section", {
      staticClass: "information",
      domProps: { innerHTML: _vm._s(_vm.$t("CONFIRMATION_PREPAID_VAT_TEXT")) },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }