var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "spinner" }, [_c("pn-spinner")], 1),
    _vm.loaderTitle
      ? _c("h2", [_vm._v(_vm._s(this.$t(_vm.loaderTitle)))])
      : _vm._e(),
    _vm.loaderDescription
      ? _c("p", [_vm._v(_vm._s(this.$t(_vm.loaderDescription)))])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }