<script>
import { mapGetters } from 'vuex';

export default ({
  computed: {
    ...mapGetters('shipment', ['item']),
  },
});
</script>

<template>
  <div class="container">
    <h1>
      {{ $t('CONFIRMATION_PREPAID_VAT_HEADER') }}
    </h1>

    <section class="shipment-information">
      <div class="row">
        <p class="bold">{{ $t('SHIPMENT_ID') }}</p>
        <p>{{ item.itemId }}</p>
      </div>
    </section>

    <section class="information" v-html="$t('CONFIRMATION_PREPAID_VAT_TEXT')" />
  </div>
</template>

<style lang="scss" scoped>
  .shipment-information {
    border-bottom: 4px solid $blue700;
    margin-bottom: 16px;
    height: auto;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .bold {
    font-weight: $bold;
  }

  .information {
    margin-bottom: 3.2rem;
  }
</style>
