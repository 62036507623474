var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("p", { staticClass: "email-description" }, [
        _vm._v(_vm._s(_vm.$t("RECEIPT_EMAIL"))),
      ]),
      _c("pn-input", {
        staticClass: "pn-vat-input",
        attrs: {
          label: "Email",
          value: _vm.receiptEmail,
          error: _vm.getErrorText() ? _vm.$t(_vm.getErrorText()) : "",
        },
        on: {
          input: function ($event) {
            _vm.receiptEmail = $event.target.value
          },
        },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.receiptSent,
              expression: "receiptSent",
            },
          ],
          staticClass: "sent-text",
        },
        [
          _c("pn-icon", {
            attrs: { symbol: "check-circle", small: "true", color: "green700" },
          }),
          _vm._v(_vm._s(_vm.$t("SEND_RECEIPT_SENT"))),
        ],
        1
      ),
      _c(
        "pn-button",
        {
          staticClass: "pn-vat-button",
          attrs: { loading: _vm.sendingReceipt },
          on: {
            click: function ($event) {
              return _vm.sendReceipt()
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.$t("SEND_RECEIPT")) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }