<script>
export default {
  data() {
    return {
      tooltipPosition: 'right',
      displayErrors: false,
      form: [
        {
          name: 'itemId',
          isValid: (value) => {
            const regx = new RegExp('^[a-zA-Z0-9]{9,35}$');
            return regx.test(value);
          },
          helpText: 'SHIPMENT_ID_HELPTEXT',
          placeholder: 'SHIPMENT_ID',
          value: '',
          errorMessage: 'INVALID_INPUT_ITEM_ID',
        },
        {
          name: 'code',
          isValid: (value) => {
            const regx = new RegExp('(^\\d{3}\\s\\d{2}$)|(^\\d{5}$)');
            return regx.test(value);
          },
          helpText: 'POSTAL_CODE_HELPTEXT',
          placeholder: 'POSTAL_CODE',
          value: '',
          errorMessage: 'INVALID_INPUT_POSTAL_CODE',
        },
      ],
    };
  },

  created() {
    if (window.matchMedia('(max-width: 880px)').matches) {
      this.tooltipPosition = 'left';
    }

    // Submit with return-key
    window.addEventListener('keyup', (event) => {
      if (event.keyCode === 13) {
        this.submit();
      }
    });

    // Tries to prefill all fields from the query params
    Object.keys(this.$route.query).forEach((param) => {
      const formInput = this.form.find((i) => i.name === param);
      if (formInput) {
        formInput.value = this.$route.query[param];
      }
    });

    if (this.form[0].value && this.form[1].value) {
      this.submit();
    }
  },

  methods: {
    submit() {
      const invalidInputs = this.form.filter((i) => !i.isValid(i.value));
      if (invalidInputs.length > 0) {
        this.displayErrors = true;
      } else {
        this.$emit('onSearch', {
          itemId: this.form[0].value,
          code: this.form[1].value,
        });
      }
    },
  },
};
</script>

<template>
  <div>
    <section>
      <p>{{$t('SE_SEARCH_DESCRIPTION')}}</p>
    </section>

    <section class="form">
      <div v-for="(input, i) in form" :key="i" class="customs-input-container">
        <pn-input
          class='pn-vat-input'
          :label="$t(input.placeholder)"
          :error="(!input.isValid(input.value) && displayErrors) ? $t(input.errorMessage) : ''"
          type="text"
          :value="input.value"
          @input="input.value = $event.target.value"
        />
        <pn-tooltip :position="tooltipPosition">{{ $t(input.helpText)}}</pn-tooltip>
      </div>
    </section>

    <pn-button
      class='pn-vat-button'
      @click="submit()"
    >
      {{ $t('SEARCH_BUTTON') }}
    </pn-button>

    <section class="disclaimer">
      <p v-html="$t('DISCLAIMER_FIRST')" />
      <p v-html="$t('DISCLAIMER_SECOND')" />
      <p v-html="$t('DISCLAIMER_THIRD')" />
      <p v-html="$t('DISCLAIMER_FOURTH')" />
    </section>
  </div>
</template>

<style lang="scss" scoped>
  section {

    &.disclaimer {
      margin-top: 4.0rem !important;

      p {
        font-size: 1.4rem;
        color: $gray700;
      }
    }
  }

  .customs-input-container {
    display: flex;
    align-items: center;
    position: relative;
  }

  pn-input input {
    padding-right: 2.4em;
  }

  pn-tooltip {
    position: absolute;
    right: 0.7em;
    top: 2.2em;
  }
</style>
