<script>
import { mapGetters } from 'vuex';
import ReceiptSummary from '@/components/ReceiptSummary.vue';

export default {
  name: 'dk-receipt',
  computed: {
    ...mapGetters('shipment', ['item']),
    fees() {
      return [
        {
          name: 'SKAT',
          rows: [
            {
              type: 'CUSTOMS',
              amount: this.getFeeAmount('CUSTOMS'),
            },
            {
              type: 'VAT',
              amount: this.getFeeAmount('VAT'),
            },
          ],
        },
        {
          name: 'PostNord',
          rows: [
            {
              type: 'HANDLING_FEE',
              amount: this.getFeeAmount('HANDLING-FEE'),
            },
            {
              type: 'HANDLING_VAT',
              amount: this.getFeeAmount('HANDLING-VAT'),
            },
          ],
        },
      ];
    },
  },
  created() {
    if (this.$route.query.itemId && (this.$route.query.code || this.$route.query.code)) {
      this.$store
        .dispatch('shipment/get', {
          itemId: this.$route.query.itemId,
          code: this.$route.query.code || this.$route.query.code,
          callback: (err) => {
            if (err) {
              this.$router.push({ name: 'search' });
            }
          },
        })
        .then(() => {
          if (this.item.status !== 'DONE' && this.item.status !== 'PAID') {
            this.$router.push({ name: 'search' });
          } else if (!this.item.gaSent) {
            this.$emit('onSendGa', this.item.pinCode);
          }
        });
    }
  },
  methods: {
    getFeeAmount(type) {
      const fee = this.item.fee.find((f) => f.type === type);
      if (!fee) {
        return 0;
      }

      return fee.amount;
    },
  },
  components: {
    ReceiptSummary,
  },
};
</script>

<template>
  <div>
    <section>
      <p>{{ $t('DK_RECEIPT_PAY_BEFORE_DESCRIPTION') }}</p>
    </section>
    <section>
      <receipt-summary
        :header="$t('FORSENDELSENR')"
        :reference="item.itemReference"
        :sections="fees"
        :total-fee="item.totalAmount"
        :paid-at="item.paidOn"
        v-model="item"
        v-if="item" />
    </section>
    <section>
      <p>{{ $t('MAIL_RECEIPT_INFORMATION') }}</p>
    </section>
    <section class="links">
      <a :href="`https://portal.postnord.com/tracking/details/${item.internationalBarCode}`" target="_blank" rel='noopener noreferrer'>{{$t('TRACK_SHIPMENT')}}</a>
      <router-link :to="{ name: 'search' }">{{ $t('NEW_SEARCH') }}</router-link>
    </section>
  </div>
</template>

<style lang="scss" scoped>
  section {
    margin-top: 2em;
  }

  p {
    margin: 1.6rem 0;
  }

  .links {
    a {
      display: block;
      margin-top: .5em;
    }
  }
</style>
