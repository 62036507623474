<script>
import { mapGetters } from 'vuex';
import Axios from 'axios';
import DkReceipt from './DkReceipt.vue';
import SeReceipt from './SeReceipt.vue';

export default ({
  data() {
    return {
      receiptEmail: '',
      sendingReceipt: false,
      receiptSent: false,
      error: '',
    };
  },
  computed: {
    ...mapGetters('shipment', ['item']),
    ...mapGetters('topbar', ['market']),
  },
  methods: {
    getComponent() {
      return `${this.market.toLowerCase()}-receipt`;
    },

    sendGa(code) {
      if (this.market === 'SE') {
        return Axios.post(`${process.env.VUE_APP_API_V2_BASE_URL}/items/${this.item.itemId}/${code}/trigger-ga`, {}).then(this.handleGa);
      }

      return Axios.get(`${process.env.VUE_APP_API_BASE_URL}/items/${this.item.itemId}/postal/${code}/ga`).then(this.handleGa);
    },

    handleGa(response) {
      if (!response.gaSent) {
        const totalAmount = this.getTotalAmount();
        const tax = this.getTax();

        window.dataLayer.push({
          event: 'transaction',
          ecommerce: {
            currencyCode: this.getCurrency(),
            purchase: {
              actionField: {
                id: this.getActionFieldId(),
                revenue: totalAmount,
                tax,
              },
              products: [
                {
                  id: 'n/a',
                  name: 'n/a',
                  price: totalAmount,
                  variant: 'n/a',
                  category: this.market === 'DK' ? 'Import VAT Denmark' : 'Import VAT Sweden',
                  quantity: 1,
                  dimension8: this.market,
                  dimension9: 'n/a',
                },
              ],
            },
          },
        });
      }
    },

    formatAmount(amount) {
      return amount.replace(' ', '').replace(',', '.');
    },

    getTotalAmount() {
      const totalAmount = this.market === 'SE' ? this.item.fee.totalFee.amount : this.item.totalAmount.amount;

      return this.formatAmount(totalAmount);
    },

    getCurrency() {
      return this.market === 'SE' ? this.item.fee.totalFee.currency : this.item.totalAmount.currency;
    },

    getTax() {
      let tax;
      if (this.market === 'SE') {
        tax = this.item.fee.handlingVatFee.amount;
      } else {
        tax = this.item.fee.find((fee) => fee.type === 'HANDLING-VAT').amount;
      }

      return this.formatAmount(tax);
    },

    getActionFieldId() {
      return this.market === 'SE' ? this.item.orderId : this.item.paymentId.toString();
    },
  },
  components: {
    DkReceipt,
    SeReceipt,
  },
});
</script>

<template>
  <div class="container">
    <h1>
      {{ $t('RECEIPT_HEADER') }}
    </h1>
    <component :is="getComponent()" @onSendGa="sendGa" />
  </div>
</template>

<style lang="scss" scoped>
</style>
