var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.item.iossAcceptedAt || _vm.item.pddpAcceptedAt
        ? _c(
            "pn-toast",
            {
              staticClass: "prepaid-vat-information",
              attrs: { icon: "alert-info-circle", closable: "false" },
            },
            [
              _c("span", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("CUSTOMS_PAYMENT_PREVIOUS_PREPAID_VAT")
                  ),
                },
              }),
            ]
          )
        : _vm._e(),
      _vm.errorStatus ? _c("section", [_vm._t("default")], 2) : _vm._e(),
      _vm._t("default"),
      _c(
        "section",
        [
          _vm.item
            ? _c("payment-summary-table", {
                attrs: {
                  header: _vm.$t("SHIPMENT_ID"),
                  reference: _vm.item.itemId,
                  "customs-header": _vm.$t("CUSTOMS_ID"),
                  "customs-reference": _vm.item.customsId,
                  "is-customs": _vm.isCustoms,
                  sections: _vm.fees,
                  total: _vm.item.fee.totalFee,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("section", [
        _c("div", { staticClass: "shipment-actions" }, [
          _vm.item.images && _vm.item.images.length > 0
            ? _c(
                "a",
                {
                  staticClass: "link",
                  on: {
                    click: function ($event) {
                      return _vm.openModal()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("SHOW_IMAGES_OF_PARCEL")))]
              )
            : _vm._e(),
        ]),
      ]),
      _c(
        "section",
        [
          _c("payment-options", {
            attrs: {
              "attempt-submit": _vm.attemptSubmit,
              id: _vm.item.id,
              code: _vm.item.code,
            },
            model: {
              value: _vm.paymentMethod,
              callback: function ($$v) {
                _vm.paymentMethod = $$v
              },
              expression: "paymentMethod",
            },
          }),
          _c(
            "p",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isValid && _vm.attemptSubmitNoPaymentMethod,
                  expression: "!isValid && attemptSubmitNoPaymentMethod",
                },
              ],
              staticClass: "error-text",
            },
            [_vm._v(_vm._s(_vm.$t("PAYMENT_OPTION_REQUIRED")))]
          ),
        ],
        1
      ),
      _vm.isCustoms
        ? _c(
            "pn-toast",
            { attrs: { icon: "alert-info-circle", closable: "false" } },
            [_vm._v(" " + _vm._s(_vm.$t("CUSTOMS_PAYMENT_INFO_BOX")) + " ")]
          )
        : _vm._e(),
      this.paymentMethod.id !== "APPLE-PAY"
        ? _c(
            "pn-button",
            {
              staticClass: "pay-button pn-vat-button",
              attrs: { loading: _vm.isPaying },
              on: {
                click: function ($event) {
                  return _vm.initPayment()
                },
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    this.paymentMethod.id !== "INVOICE"
                      ? this.$t("PAY")
                      : this.$t("COMPLETE")
                  ) +
                  " "
              ),
            ]
          )
        : _vm._e(),
      _c("div", { attrs: { id: "apple-pay-container" } }),
      !_vm.isCustoms
        ? _c(
            "a",
            {
              staticClass: "customs-representative",
              attrs: {
                href: _vm.$t("CUSTOMS_REPRESENTATIVE_LINK"),
                target: "_blank",
                rel: "noopener noreferrer",
              },
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("CUSTOMS_REPRESENTATIVE_LINK_TEXT")) + " "
              ),
            ]
          )
        : _vm._e(),
      _c("section", { staticClass: "disclaimer" }, [
        _c("p", {
          domProps: { innerHTML: _vm._s(_vm.$t("DISCLAIMER_FIRST_PAYMENT")) },
        }),
        !_vm.isCustoms
          ? _c("p", {
              domProps: {
                innerHTML: _vm._s(_vm.$t("DISCLAIMER_SECOND_PAYMENT")),
              },
            })
          : _vm._e(),
      ]),
      _c(
        "pn-modal",
        {
          staticClass: "pn-vat-modal",
          attrs: { open: this.isImagesModalOpen, id: "images-modal" },
          on: { close: () => (this.isImagesModalOpen = false) },
        },
        [
          _c("h1", [_vm._v(_vm._s(_vm.$t("IMAGES_OF_PARCEL")))]),
          _vm._l(_vm.item.images, function (image, i) {
            return _c("div", { key: i, staticClass: "image" }, [
              _c("img", { attrs: { src: image, alt: "parcel" } }),
            ])
          }),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }