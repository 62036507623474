var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _vm.market === "DK"
        ? _c("Tile", {
            staticClass: "download-app-tile",
            attrs: {
              link: "https://app.postnord.com/download/",
              illustration: "hand-app-map",
              heading: _vm.$t("DOWNLOAD_APP_TILE_TITLE"),
              content: _vm.$t("DOWNLOAD_APP_TILE_CONTENT"),
            },
          })
        : _vm._e(),
      _c("h1", [_vm._v(_vm._s(_vm.$t("YOUR_SHIPMENT")))]),
      _c("p", { staticClass: "info" }, [
        _vm._v(" " + _vm._s(_vm.$t("CURRENT_INFO_TEXT")) + " ("),
        _c(
          "a",
          {
            attrs: {
              href: `https://portal.postnord.com/tracking/details/${_vm.item.internationalBarCode}`,
              target: "_blank",
              rel: "noopener noreferrer",
            },
          },
          [_vm._v(_vm._s(_vm.$t("TRACK_AND_TRACE")))]
        ),
        _vm._v("): "),
      ]),
      _vm.isInactive
        ? _c(
            "section",
            [
              _c("error-message", {
                attrs: {
                  title: _vm.errors[_vm.item.status].title,
                  description: _vm.errors[_vm.item.status].description,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      !_vm.isInactive
        ? _c(
            "section",
            [
              _vm.item
                ? _c("payment-summary-table", {
                    attrs: {
                      header: _vm.$t("SHIPMENT_ID"),
                      reference:
                        _vm.item.internationalBarCode || _vm.item.itemReference,
                      sections: _vm.fees,
                      total: _vm.item.totalAmount,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.isInactive
        ? _c(
            "section",
            [
              _c(
                "pn-button",
                {
                  staticClass: "pn-vat-button",
                  attrs: { appearance: "secondary" },
                  on: {
                    click: function ($event) {
                      return _vm.back()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("BACK")) + " ")]
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.isInactive
        ? _c(
            "section",
            [
              _c("span", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("ACCEPT_REJECT_PACKAGE_INFORMATION")
                  ),
                },
              }),
              _c("pn-tooltip", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("ACCEPT_REJECT_PACKAGE_INFORMATION_DETAILS")
                    ) +
                    " "
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      !_vm.isInactive
        ? _c(
            "section",
            { staticClass: "accept-terms" },
            [
              _c("pn-checkbox", {
                attrs: {
                  name: "accept-terms",
                  checkboxid: "accept-terms",
                  value: "test",
                  checked: _vm.termsAccepted,
                },
                on: {
                  change: function ($event) {
                    _vm.termsAccepted = $event.target.checked
                  },
                },
              }),
              _c("div", [
                _c("label", { attrs: { for: "accept-terms" } }, [
                  _vm._v(_vm._s(_vm.$t("I_ACCEPT_TERMS"))),
                ]),
                _c(
                  "a",
                  {
                    attrs: {
                      href: _vm.$t("TERMS_AND_CONDITION_LINK"),
                      target: "_blank",
                      rel: "noopener noreferrer",
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("SHIPMENT_TERMS")))]
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.attemptSubmit && !_vm.termsAccepted,
              expression: "attemptSubmit && !termsAccepted",
            },
          ],
          staticClass: "check-validation-text",
        },
        [_vm._v(_vm._s(_vm.$t("I_ACCEPT_TERMS_ERROR")))]
      ),
      !_vm.isInactive
        ? _c("div", [
            _c(
              "section",
              [
                _c(
                  "pn-button",
                  {
                    staticClass: "pn-vat-button",
                    on: {
                      click: function ($event) {
                        return _vm.submit()
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("ACCEPT")) + " ")]
                ),
                _c(
                  "div",
                  { staticClass: "reject-btn" },
                  [
                    _c(
                      "pn-button",
                      {
                        staticClass: "pn-vat-button",
                        attrs: { appearance: "secondary" },
                        on: {
                          click: function ($event) {
                            return _vm.openModal()
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("REJECT_SHIPMENT")) + " ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "docs-row-section" }, [
              _c(
                "div",
                { staticClass: "docs-row" },
                [
                  _c(
                    "pn-modal",
                    {
                      staticClass: "pn-vat-modal",
                      attrs: { open: this.isRejectModalOpen },
                      on: { close: () => (this.isRejectModalOpen = false) },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "pn-modal-content" },
                        [
                          _c("h2", [_vm._v(_vm._s(_vm.$t("REJECT_SHIPMENT")))]),
                          _c("section", [
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.$t("REJECT_SHIPMENT_CONFIRMATION"))
                              ),
                            ]),
                          ]),
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.$t("SHIPMENT_MODAL_EMAIL_DESCRIPTION"))
                            ),
                          ]),
                          _c("pn-input", {
                            staticClass: "pn-vat-input",
                            attrs: {
                              label: _vm.$t("EMAIL"),
                              error:
                                _vm.attemptReject && !_vm.validateEmail
                                  ? _vm.$t("EMAIL_ERROR")
                                  : "",
                              value: _vm.rejectEmail,
                            },
                            on: {
                              input: function ($event) {
                                _vm.rejectEmail = $event.target.value
                              },
                            },
                          }),
                          _c(
                            "section",
                            [
                              _c(
                                "pn-button",
                                {
                                  staticClass: "pn-vat-button",
                                  attrs: { loading: _vm.isLoading },
                                  on: {
                                    click: function ($event) {
                                      return _vm.confirmReject()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("SHIPMENT_MODAL_CONFIRM")) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "pn-button",
                                {
                                  staticClass: "pn-vat-button",
                                  attrs: { appearance: "secondary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.closeModal()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("SHIPMENT_MODAL_ABORT")) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }