<script>
import { mapGetters } from 'vuex';
import ReceiptSummary from '@/components/ReceiptSummary.vue';
import SendReceipt from '@/components/SendReceipt.vue';

export default ({
  data() {
    return {
      receiptEmail: '',
      sendingReceipt: false,
      isCustoms: null,
    };
  },
  computed: {
    ...mapGetters('shipment', ['item']),

    fees() {
      const fees = [
        {
          name: 'VAT',
          rows: [
            {
              type: 'VAT',
              amount: this.getFeeAmount('importVatFee'),
            },
          ],
        },
        {
          name: 'DECLARATION_FEE',
          rows: [
            {
              type: 'HANDLING_FEE',
              amount: this.getFeeAmount('handlingOnlyFee'),
            },
            {
              type: 'HANDLING_VAT',
              amount: this.getFeeAmount('handlingVatFee'),
            },
            {
              type: 'HANDLING_FEE_TOTAL',
              amount: this.getFeeAmount('handlingWithVatFee'),
            },
          ],
        },
      ];

      if (this.isCustoms) {
        const customsFees = {
          name: 'CUSTOMS_FEE',
          rows: [
            {
              type: 'CUSTOMS',
              amount: this.getFeeAmount('customsFee'),
            },
          ],
        };
        fees.splice(1, 0, customsFees);
      }

      return fees;
    },
  },
  created() {
    if (this.$route.query.itemId && (this.$route.query.code || this.$route.query.code)) {
      this.$store
        .dispatch('shipment/getV2', {
          itemId: this.$route.query.itemId,
          code: this.$route.query.code,
          callback: (err) => {
            if (err) {
              this.$router.push({ name: 'search' });
            }
          },
        })
        .then(() => {
          if (this.item.status !== 'DONE' && this.item.status !== 'PAID') {
            this.$router.push({ name: 'search' });
          } else if (!this.item.gaSent) {
            this.$emit('onSendGa', this.item.code);
          }
        });
    }

    this.isCustoms = !!this.item.customsId;
  },

  methods: {
    getFeeAmount(type) {
      return this.item.fee[type].amount || 0;
    },

    print() {
      window.print();
    },
  },
  components: {
    ReceiptSummary,
    SendReceipt,
  },
});
</script>

<template>
  <div>
    <section class="no-print">
      <p>{{ isCustoms ? $t('CUSTOMS_RECEIPT_DELIVERY_TEXT') : $t('RECEIPT_DELIVERY_TEXT') }}</p>
      <p v-if="isCustoms">{{ $t('CUSTOMS_RECEIPT_INVOICE_TEXT') }}</p>
    </section>
    <section>
      <receipt-summary
        :header="$t('SHIPMENT_ID')"
        :reference="item.itemId"
        :sections="fees"
        :total-fee="item.fee.totalFee"
        :paid-at="item.paidAt"
        :is-customs="isCustoms"
        v-model="item"
        v-if="item" />
    </section>

    <div class="no-print">
      <section>
        <div class="shipment-actions">
          <a class="link" @click="print()">{{ $t('PRINT_RECEIPT') }}</a>
          <a class="link" :href="`https://portal.postnord.com/tracking/details/${item.itemId}`" target="_blank" rel='noopener noreferrer'>{{ $t('TRACK_PARCEL') }}</a>
        </div>
      </section>

      <send-receipt :code="item.code" :item-id="item.itemId" />
    </div>

    <section class="no-print new-search">
      <router-link :to="{ name: 'search' }">{{$t('NEW_SEARCH')}}</router-link>
    </section>
  </div>
</template>

<style lang="scss">
  .shipment-actions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    a {
      margin-top: .4rem;

      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  .new-search {
    text-align: center;
  }
</style>
