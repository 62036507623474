var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.currentStep !== null
    ? _c("div", [
        _vm.meta
          ? _c(
              "div",
              {
                staticClass: "header",
                style: { "background-color": _vm.meta.headerColor },
              },
              [
                _c("div", { staticClass: "image-container" }, [
                  _c("img", {
                    attrs: {
                      alt: "customs illustration",
                      src: require(`../assets/${_vm.meta.header}`),
                    },
                  }),
                ]),
              ]
            )
          : _vm._e(),
        _vm.showStepper
          ? _c(
              "div",
              { staticClass: "steps", class: { invisible: _vm.isInvisible } },
              _vm._l(_vm.steps, function (step, i) {
                return _c(
                  "span",
                  {
                    key: i,
                    class: {
                      "router-link-active": _vm.isActive(step),
                      "router-link-visited": _vm.isVisited(i),
                    },
                  },
                  [
                    _c("pn-icon", {
                      attrs: {
                        symbol: step.icon,
                        color: _vm.iconColor(_vm.isActive(step)),
                        small: false,
                        solid: "",
                      },
                    }),
                    _c("p", { staticClass: "desktop-only" }, [
                      _vm._v(_vm._s(_vm.$t(step.name))),
                    ]),
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }