var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("h1", [_vm._v(" " + _vm._s(_vm.$t("RECEIPT_HEADER")) + " ")]),
      _c(_vm.getComponent(), {
        tag: "component",
        on: { onSendGa: _vm.sendGa },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }