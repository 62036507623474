var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "tile",
      attrs: { href: _vm.link, target: "_blank", rel: "noopener noreferrer" },
    },
    [
      _c("div", { staticClass: "illustration-wrapper" }, [
        _c("img", {
          staticClass: "illustration",
          attrs: {
            alt: "",
            src: require(`../assets/illustrations/${_vm.illustration}.svg`),
          },
        }),
      ]),
      _c("div", [
        _c("h3", { staticClass: "heading" }, [_vm._v(_vm._s(_vm.heading))]),
        _c("p", [_vm._v(_vm._s(_vm.content))]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }