<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters('shipment', [
      'item',
    ]),
  },
  methods: {
    back() {
      this.$router.push({ name: 'search' });
    },
  },
};
</script>

<template>
  <div class="container">
    <h1>{{$t('YOUR_INVOICE_ANNULLED')}}</h1>

    <section class="id">
      <div>
        <span class="info">{{$t('SHIPMENT_ID')}}</span>
        <span>{{item.internationalBarCode || item.itemId}}</span>
      </div>
    </section>

    <section>
      <p>{{$t('ANNULLED_DETAILS')}}</p>
    </section>

    <section>
      <pn-button
        class='pn-vat-button'
        appearance="secondary"
        @click="back()"
      >
        {{ $t('NEW_SEARCH') }}
      </pn-button>
    </section>
  </div>
</template>

<style lang="scss" scoped>
  .id {
    display:  flex;
    justify-content: space-between;

    > div {
      color: $gray400;
      border-bottom: 2px solid $blue700;
      width: 100%;

      > span {
        float: right;
        margin: 0 0 8px;

        &.info {
          color: $gray900;
          font-weight: $bold;
          float: none;
        }
      }
    }
  }
</style>
