export default {
  SE: {
    showStepper: true,
    steps: [
      {
        name: 'STEP_SEARCH',
        component: 'search',
        icon: 'search',
      },
      {
        name: 'STEP_PARCEL',
        component: 'customs-shipment',
        icon: 'box',
      },
      {
        name: 'STEP_CUSTOMS_DECLARATION',
        component: 'customs-declaration',
        icon: 'clock',
      },
      {
        name: 'STEP_PAYMENT',
        component: 'payment',
        icon: 'credit-card',
      },
      {
        name: 'STEP_RECEIPT',
        component: 'receipt',
        icon: 'receipt',
      },
    ],
  },

  DK: {
    showStepper: true,
    steps: [
      {
        name: 'STEP_SEARCH',
        component: 'search',
        icon: 'search',
      },
      {
        name: 'STEP_SHIPMENT',
        component: 'shipment',
        icon: 'box',
      },
      {
        name: 'STEP_PAYMENT',
        component: 'payment',
        icon: 'credit-card',
      },
      {
        name: 'STEP_RECEIPT',
        component: 'receipt',
        icon: 'receipt',
      },
    ],
  },
};
