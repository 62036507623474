var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container customs" },
    [
      _c("h1", [_vm._v(_vm._s(_vm.$t("YOUR_PARCEL")))]),
      _c("p", [
        _vm._v(
          _vm._s(
            _vm.$t("CUSTOMS_DECLARATION_INFO", {
              consignor: _vm.item.consignor.name,
            })
          )
        ),
      ]),
      _vm.item
        ? _c("payment-summary-table", {
            attrs: {
              "is-customs": true,
              "is-cost-preliminary": true,
              header: _vm.$t("SHIPMENT_ID"),
              reference: _vm.item.itemId,
              sections: _vm.fees,
              total: _vm.item.fee.totalFee,
            },
          })
        : _vm._e(),
      _vm.item
        ? _c("p", {
            staticClass: "customs-info fee",
            domProps: { innerHTML: _vm._s(_vm.$t("CUSTOMS_PRELIMINARY_FEE")) },
          })
        : _vm._e(),
      _vm.item.images && _vm.item.images.length > 0
        ? _c("section", [
            _c("div", { staticClass: "shipment-actions" }, [
              _c(
                "a",
                {
                  staticClass: "link",
                  on: {
                    click: function ($event) {
                      return _vm.openModal()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("SHOW_IMAGES_OF_PARCEL")))]
              ),
            ]),
          ])
        : _vm._e(),
      _c(
        "form",
        { staticClass: "customs-form" },
        [
          _c("p", [_vm._v(_vm._s(_vm.$t("CUSTOMS_PAYMENT_METHODS_INFO")))]),
          _c(
            "div",
            { staticClass: "checkbox-wrapper" },
            [
              _c("pn-checkbox", {
                attrs: {
                  checkboxid: "company-invoice",
                  name: "company-invoice",
                  value: "company-invoice",
                  checked: _vm.isInvoiceRequested,
                },
                on: {
                  change: function ($event) {
                    _vm.isInvoiceRequested = $event.target.checked
                  },
                },
              }),
              _c("label", { attrs: { for: "company-invoice" } }, [
                _vm._v(" " + _vm._s(_vm.$t("CUSTOMS_COMPANY")) + " "),
              ]),
              _c("p", { staticClass: "customs-info invoice" }, [
                _vm._v(_vm._s(_vm.$t("INVOICE_INFO"))),
              ]),
            ],
            1
          ),
          _c("p", { staticClass: "contact-info-text" }, [
            _vm._v(_vm._s(_vm.$t("CUSTOMS_INPUT_DESCRIPTION"))),
          ]),
          _c("pn-input", {
            staticClass: "pn-vat-input",
            attrs: {
              label: _vm.$t("PHONE_NUMBER"),
              error: _vm.getPhoneErrorText()
                ? _vm.$t(_vm.getPhoneErrorText())
                : "",
              type: "tel",
              autocomplete: "tel",
              value: _vm.phoneNumber,
            },
            on: {
              input: function ($event) {
                _vm.phoneNumber = $event.target.value
              },
              keyup: _vm.resetAttemptSubmit,
            },
          }),
          _c("pn-input", {
            staticClass: "pn-vat-input",
            attrs: {
              label: _vm.$t("EMAIL_OPTIONAL"),
              error:
                _vm.attemptSubmit && !_vm.isEmailValid && !!_vm.email
                  ? _vm.$t("EMAIL_INVALID")
                  : "",
              type: "email",
              autocomplete: "email",
              value: _vm.email,
            },
            on: {
              input: function ($event) {
                _vm.email = $event.target.value
              },
              keyup: _vm.resetAttemptSubmit,
            },
          }),
          _c(
            "div",
            { staticClass: "checkbox-wrapper" },
            [
              _c("pn-checkbox", {
                attrs: {
                  checkboxid: "accept",
                  name: "accept",
                  value: "accept",
                  checked: _vm.isAccepted,
                },
                on: {
                  change: function ($event) {
                    _vm.isAccepted = $event.target.checked
                  },
                },
              }),
              _c(
                "label",
                { staticClass: "accept-text", attrs: { for: "accept" } },
                [_vm._v(_vm._s(_vm.$t("CUSTOMS_ACCEPT_REPRESENTATIVE")))]
              ),
              !_vm.isAccepted && _vm.attemptSubmit
                ? _c("p", { staticClass: "error-text" }, [
                    _vm._v(_vm._s(_vm.$t("CUSTOMS_ACCEPT_VALIDATION_ERROR"))),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "pn-button",
            {
              staticClass: "pn-vat-button",
              attrs: { loading: _vm.isLoading },
              on: { click: _vm.next },
            },
            [_vm._v(" " + _vm._s(_vm.$t("NEXT")) + " ")]
          ),
          _c(
            "p",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.error,
                  expression: "error",
                },
              ],
              staticClass: "error-text",
            },
            [_vm._v(_vm._s(_vm.$t("ACCEPT_ERROR")))]
          ),
          _c(
            "a",
            {
              staticClass: "customs-representative",
              attrs: {
                href: _vm.$t("CUSTOMS_REPRESENTATIVE_LINK"),
                target: "_blank",
                rel: "noopener noreferrer",
              },
            },
            [_vm._v(_vm._s(_vm.$t("CUSTOMS_REPRESENTATIVE_LINK_TEXT")))]
          ),
        ],
        1
      ),
      _c(
        "a",
        {
          staticClass: "read-more",
          attrs: {
            href: _vm.$t("CUSTOMS_READ_MORE_LINK"),
            target: "_blank",
            rel: "noopener noreferrer",
          },
        },
        [_vm._v(_vm._s(_vm.$t("CUSTOMS_READ_MORE_LINK_TEXT")))]
      ),
      _c("p", { staticClass: "customs-info" }, [
        _vm._v(_vm._s(_vm.$t("CUSTOMS_PROCESS_INFO"))),
      ]),
      _c(
        "pn-modal",
        {
          staticClass: "pn-vat-modal",
          attrs: { open: this.isImagesModalOpen },
          on: { close: () => (this.isImagesModalOpen = false) },
        },
        [
          _c("h1", [_vm._v(_vm._s(_vm.$t("IMAGES_OF_PARCEL")))]),
          _vm._l(_vm.item.images, function (image, i) {
            return _c("div", { key: i, staticClass: "image" }, [
              _c("img", { attrs: { src: image, alt: "parcel" } }),
            ])
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }