<script>

export default {
  props: ['itemId', 'code'],
  data() {
    return {
      receiptEmail: '',
      sendingReceipt: false,
      receiptSent: false,
      error: null,
      attemptSubmit: false,
    };
  },
  computed: {
    validateEmail() {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(this.receiptEmail).toLowerCase());
    },
  },
  methods: {
    getErrorText() {
      if (this.error) {
        return 'SEND_RECEIPT_ERROR';
      }

      if (this.attemptSubmit) {
        if (this.receiptEmail) {
          if (!this.validateEmail) {
            return 'EMAIL_ERROR';
          }
        } else {
          return 'EMAIL_REQUIRED';
        }
      }

      return '';
    },
    sendReceipt() {
      this.attemptSubmit = true;
      if (!this.validateEmail) {
        return;
      }

      this.sendingReceipt = true;
      this.receiptSent = false;
      this.error = null;

      const data = {
        request: {
          email: this.receiptEmail,
        },
        item: {
          itemId: this.itemId,
          code: this.code,
        },
      };

      this.$store.dispatch('shipment/sendReceipt', data)
        .then(() => {
          this.sendingReceipt = false;
          this.receiptSent = true;
        })
        .catch((error) => {
          this.error = error;
          this.sendingReceipt = false;
        });
    },
  },
};
</script>

<template>
  <section>
    <p class="email-description">{{ $t('RECEIPT_EMAIL') }}</p>

    <pn-input
      class='pn-vat-input'
      label="Email"
      :value="receiptEmail"
      @input="receiptEmail = $event.target.value"
      :error="getErrorText() ? $t(getErrorText()) : ''"
    />
    <div class="sent-text" v-show="receiptSent"><pn-icon symbol="check-circle" small="true" color="green700" />{{ $t('SEND_RECEIPT_SENT') }}</div>

    <pn-button
      class='pn-vat-button'
      :loading="sendingReceipt"
      @click="sendReceipt()"
    >
      {{ $t('SEND_RECEIPT') }}
    </pn-button>
  </section>
</template>

<style lang="scss" scoped>
  p {
    margin: 3.2rem 0 0.8rem;
  }

  .sent-text {
    margin: 0;
    color: $green700;
    display: flex;
    align-items: center;

    .pn-icon {
      margin-right: 0.2em;
    }
  }
</style>
