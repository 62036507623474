import { render, staticRenderFns } from "./Accept.vue?vue&type=template&id=ec6c0636&scoped=true&"
import script from "./Accept.vue?vue&type=script&lang=js&"
export * from "./Accept.vue?vue&type=script&lang=js&"
import style0 from "./Accept.vue?vue&type=style&index=0&id=ec6c0636&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec6c0636",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ec6c0636')) {
      api.createRecord('ec6c0636', component.options)
    } else {
      api.reload('ec6c0636', component.options)
    }
    module.hot.accept("./Accept.vue?vue&type=template&id=ec6c0636&scoped=true&", function () {
      api.rerender('ec6c0636', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/accept/Accept.vue"
export default component.exports