var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "error-msg-container" }, [
    _c("div", { staticClass: "error-msg" }, [
      _c("span", { staticClass: "error-title" }, [
        _vm._v(_vm._s(_vm.$t(_vm.title))),
      ]),
      _c("span", { staticClass: "error-description" }, [
        _vm._v(_vm._s(_vm.$t(_vm.description))),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }