var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("p", { staticClass: "description" }, [
        _vm._v(_vm._s(_vm.$t("DK_SEARCH_DESCRIPTION"))),
      ]),
      _c(
        "section",
        { staticClass: "form" },
        _vm._l(_vm.form, function (input, i) {
          return _c("pn-input", {
            key: i,
            staticClass: "pn-vat-input",
            attrs: {
              label: _vm.$t(input.placeholder),
              error:
                !input.isValid(input.value) && _vm.displayErrors
                  ? _vm.$t(input.errorMessage)
                  : "",
              type: "text",
              value: input.value,
            },
            on: {
              input: function ($event) {
                input.value = $event.target.value
              },
            },
          })
        }),
        1
      ),
      _c("p", [_vm._v(_vm._s(_vm.$t("DK_SEARCH_EXPLANATION")))]),
      _c(
        "pn-button",
        {
          staticClass: "pn-vat-button",
          on: {
            click: function ($event) {
              return _vm.submit()
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.$t("SEARCH_BUTTON")) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }