var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "payment-summary" },
    [
      _c("div", { staticClass: "payment-header" }, [
        _c("div", { staticClass: "row" }, [
          _c("p", [_vm._v(_vm._s(_vm.$t("SHIPMENT_ID")))]),
          _vm.market === "SE"
            ? _c("p", [_vm._v(_vm._s(_vm.value.itemId))])
            : _vm._e(),
          _vm.market === "DK"
            ? _c("p", [_vm._v(_vm._s(_vm.value.internationalBarCode))])
            : _vm._e(),
        ]),
        _vm.isCustoms
          ? _c("div", { staticClass: "row" }, [
              _c("p", [_vm._v(_vm._s(_vm.$t("CUSTOMS_ID")))]),
              _c("p", [_vm._v(_vm._s(_vm.value.customsId))]),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("p", [_vm._v(_vm._s(_vm.$t("PAID_DATE")))]),
        _c("p", [_vm._v(_vm._s(_vm.paidDate || "N/A"))]),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("p", [_vm._v(_vm._s(_vm.$t("PAYMENT_METHOD")))]),
        _c("p", [_vm._v(_vm._s(_vm.$t(_vm.value.paymentMethod) || "N/A"))]),
      ]),
      _c("div", { staticClass: "row" }, [
        _c("p", [_vm._v(_vm._s(_vm.$t("ORDER_NUMBER")))]),
        _c("p", [
          _vm._v(_vm._s(_vm.value.cartId || _vm.value.orderId || "N/A")),
        ]),
      ]),
      _vm._l(_vm.sections, function (section, i) {
        return _c("div", { key: i, staticClass: "row" }, [
          _c("p", [_vm._v(_vm._s(_vm.$t(section.name)))]),
          _c("p", [_vm._v(_vm._s(_vm.sectionTotal(section)))]),
        ])
      }),
      _c("div", { staticClass: "payment-sum row" }, [
        _c("p", [_vm._v(_vm._s(_vm.$t("AMOUNT_PAYED")))]),
        _c("p", { staticClass: "total" }, [
          _c("span", [_vm._v(_vm._s(_vm.totalFee.amount))]),
          _vm._v(" " + _vm._s(_vm.totalFee.currency)),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }