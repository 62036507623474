<script>

export default ({
  props: {
    link: {
      type: String,
      required: true,
    },
    illustration: {
      type: String,
      required: true,
    },
    heading: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
  },
});
</script>

<template>
  <a class="tile" :href="link" target="_blank" rel="noopener noreferrer">
    <div class="illustration-wrapper">
      <img class="illustration" alt="" :src="require(`../assets/illustrations/${illustration}.svg`)" />
    </div>
    <div>
      <h3 class="heading">{{ heading }}</h3>
      <p>{{ content }}</p>
    </div>
  </a>
</template>

<style lang="scss">
  .tile {
    border: 0.16rem solid #D3CECB;
    background: #FFF;
    outline: none;
    display: flex;
    padding: 3.2rem;
    border-radius: 0.8rem;
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13);
    transition: box-shadow 0.1s cubic-bezier(0.6, 0, 0.2, 1) 0.1s, background 0.2s linear, border 0.1s linear, -webkit-box-shadow 0.1s cubic-bezier(0.6, 0, 0.2, 1) 0.1s;
    color: inherit !important;

    &:hover, &:focus {
      border-color: #8EDDF9;
      background: #EFFBFF;
    }

    &:focus {
      outline: 3px solid #005D92;
    }

    .illustration-wrapper {
      margin-right: 3.2rem;

      .illustration {
        width: 100px;
      }
    }

    .heading {
      font-size: 2.4rem;
      color: #005D92;
      margin-top: 0;
      margin-bottom: 0.8rem;
    }

    @media #{$phone} {
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin-left: 1.6rem;
      margin-right: 1.6rem;
      padding: 3.2rem 2.4rem 4.0rem 2.4rem;

      .illustration-wrapper {
        margin-right: 0;
        margin-bottom: 2.4rem;

        .illustration {
          width: 120px;
        }
      }
    }
  }
</style>
