var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h3", [_vm._v(_vm._s(_vm.$t("PAY_WITH")))]),
      _vm.shouldShowPaymentMethods && _vm.availablePaymentMethods.length > 0
        ? _vm._l(_vm.availablePaymentMethods, function (paymentMethod, i) {
            return _c(
              "div",
              {
                key: i,
                staticClass: "method",
                class: {
                  swish:
                    (paymentMethod.method === "SWISH-ECOMMERCE" ||
                      paymentMethod.method === "MOBILE-PAY") &&
                    (_vm.selectedMethod.id === "SWISH-ECOMMERCE" ||
                      _vm.selectedMethod.id === "MOBILE-PAY"),
                },
                on: {
                  click: function ($event) {
                    _vm.selectedMethod.id = paymentMethod.method
                  },
                },
              },
              [
                _vm.getName(paymentMethod.method)
                  ? [
                      _c("div", [
                        _c("div", { staticClass: "radio" }, [
                          _c("input", {
                            staticClass: "radio-button",
                            attrs: { id: paymentMethod.method, type: "radio" },
                            domProps: {
                              checked:
                                _vm.selectedMethod.id === paymentMethod.method,
                            },
                          }),
                        ]),
                        _c("label", { attrs: { for: paymentMethod.method } }, [
                          _vm._v(_vm._s(_vm.getName(paymentMethod.method))),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "logos" },
                        _vm._l(paymentMethod.subMethods, function (sub, i) {
                          return _c("div", { key: i }, [
                            _vm.getLogo(paymentMethod.method, sub)
                              ? _c("img", {
                                  attrs: {
                                    src: require(`../assets/images/${_vm.getLogo(
                                      paymentMethod.method,
                                      sub
                                    )}`),
                                    height: "20px",
                                    alt: "",
                                  },
                                })
                              : _vm._e(),
                          ])
                        }),
                        0
                      ),
                      _vm.isSelectedMethod("SWISH-ECOMMERCE") &&
                      _vm.isMobileMethod(paymentMethod.method)
                        ? _c(
                            "div",
                            { staticClass: "mobile-input" },
                            [
                              _c("pn-input", {
                                staticClass: "pn-vat-input",
                                attrs: {
                                  label: _vm.$t("MOBILE_NUMBER"),
                                  value: _vm.selectedMethod.mobileNumber,
                                  error: _vm.getPhoneErrorText(
                                    _vm.selectedMethod.mobileNumber
                                  )
                                    ? _vm.$t(
                                        _vm.getPhoneErrorText(
                                          _vm.selectedMethod.mobileNumber
                                        )
                                      )
                                    : "",
                                  type: "tel",
                                  autocomplete: "tel",
                                },
                                on: {
                                  input: function ($event) {
                                    _vm.selectedMethod.mobileNumber =
                                      $event.target.value
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  : _vm._e(),
              ],
              2
            )
          })
        : _vm.shouldShowPaymentMethods &&
          _vm.availablePaymentMethods.length === 0
        ? [_c("p", [_vm._v(_vm._s(_vm.$t("NO_PAYMENT_METHODS_AVAILABLE")))])]
        : _c("loader"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }