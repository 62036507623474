var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { staticClass: "no-print" }, [
      _c("p", [
        _vm._v(
          _vm._s(
            _vm.isCustoms
              ? _vm.$t("CUSTOMS_RECEIPT_DELIVERY_TEXT")
              : _vm.$t("RECEIPT_DELIVERY_TEXT")
          )
        ),
      ]),
      _vm.isCustoms
        ? _c("p", [_vm._v(_vm._s(_vm.$t("CUSTOMS_RECEIPT_INVOICE_TEXT")))])
        : _vm._e(),
    ]),
    _c(
      "section",
      [
        _vm.item
          ? _c("receipt-summary", {
              attrs: {
                header: _vm.$t("SHIPMENT_ID"),
                reference: _vm.item.itemId,
                sections: _vm.fees,
                "total-fee": _vm.item.fee.totalFee,
                "paid-at": _vm.item.paidAt,
                "is-customs": _vm.isCustoms,
              },
              model: {
                value: _vm.item,
                callback: function ($$v) {
                  _vm.item = $$v
                },
                expression: "item",
              },
            })
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "no-print" },
      [
        _c("section", [
          _c("div", { staticClass: "shipment-actions" }, [
            _c(
              "a",
              {
                staticClass: "link",
                on: {
                  click: function ($event) {
                    return _vm.print()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("PRINT_RECEIPT")))]
            ),
            _c(
              "a",
              {
                staticClass: "link",
                attrs: {
                  href: `https://portal.postnord.com/tracking/details/${_vm.item.itemId}`,
                  target: "_blank",
                  rel: "noopener noreferrer",
                },
              },
              [_vm._v(_vm._s(_vm.$t("TRACK_PARCEL")))]
            ),
          ]),
        ]),
        _c("send-receipt", {
          attrs: { code: _vm.item.code, "item-id": _vm.item.itemId },
        }),
      ],
      1
    ),
    _c(
      "section",
      { staticClass: "no-print new-search" },
      [
        _c("router-link", { attrs: { to: { name: "search" } } }, [
          _vm._v(_vm._s(_vm.$t("NEW_SEARCH"))),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }