import Axios from 'axios';

export default {
  namespaced: true,

  state: {
    item: null,
    receiverId: null,
    transactionDate: null,
  },

  mutations: {
    SET(state, shipment) {
      state.item = shipment.item;
      state.receiverId = shipment.receiverId;
      state.transactionDate = shipment.transactionDate;
    },

    RESET(state) {
      state.item = null;
      state.receiverId = null;
      state.transactionDate = null;
    },
  },

  actions: {
    // Fetches the shipment
    get(context, { itemId, code }) {
      const date = new Date().toISOString();
      const cleanCode = `${code}`.replace(' ', '');

      const url = `${process.env.VUE_APP_API_BASE_URL}/items/${itemId.toUpperCase()}/postal/${cleanCode}?timestamp=${date}`;

      return Axios.get(url).then((res) => {
        console.log('shiiiopment res.data');
        console.log(res);
        context.commit('SET', res.data);
        return res.data;
      });
    },

    getV2(context, { itemId, code }) {
      console.log('getV2');
      const url = `${process.env.VUE_APP_API_V2_BASE_URL}/items/${itemId.toUpperCase()}/${code.replace(' ', '')}`;
      console.log('getV2: ', url);
      return Axios.get(url).then((res) => {
        console.log('res.data');
        console.log(res);

        context.commit('SET', res.data);
        return res.data;
      });
    },

    // Updates the status of a shipment
    updateStatus({ state }, status) {
      state.item.status = status;
    },

    // Rejects a shipment (DK only)
    reject(context, { email }) {
      const url = `${process.env.VUE_APP_API_BASE_URL}/carts/payment/reject`;

      return Axios.post(url, {
        email,
        itemId: context.state.item.itemId,
        internationalBarCode: context.state.item.internationalBarCode,
      });
    },

    // Initialized the payment process
    requestPayment(context, { request, item }) {
      const baseUrl = process.env.VUE_APP_API_BASE_URL;
      const url = `${baseUrl}/items/${item.id}/${item.code}/pay`;

      return Axios.post(url, request).then((response) => response.data);
    },

    // Initialized the payment process
    requestPaymentV2(context, { request, item }) {
      const baseUrl = process.env.VUE_APP_API_V2_BASE_URL;
      const url = `${baseUrl}/items/${item.id}/${item.code}/pay`;

      return Axios.post(url, request).then((response) => response.data);
    },

    // Completes the payment process with first call
    // subsequent calls will check if the payment is done
    getCartStatus(context, { request, cartId }) {
      const url = `${process.env.VUE_APP_API_BASE_URL}/carts/${cartId}/status`;

      return Axios.post(url, request).then((response) => response.data);
    },

    // Sends an receipt email to the customer (SV only)
    sendReceipt(context, { request, item }) {
      const url = `${process.env.VUE_APP_API_V2_BASE_URL}/items/${item.itemId}/${item.code}/receipt`;

      return Axios.post(url, request);
    },

    // Accept PostNord as customs representative and send customs declaration request to Swedish Customs' service
    sendCustomsDeclaration(context, { request, item }) {
      const baseUrl = process.env.VUE_APP_API_V2_BASE_URL;
      const url = `${baseUrl}/items/${item.id}/${item.code}/accept`;

      return Axios.post(url, request).then((response) => response.data);
    },
  },

  getters: {
    item: ((state) => state.item),
    isInitialized: ((state) => (state.item !== null && Object.keys(state.item).length > 0)),
    shipmentFees: ((state) => {
      if (!state.item) { return []; }

      const fees = [...state.item.fee];
      const vatRows = [
        {
          vat: 'HANDLING-VAT',
          output: 'HANDLING_TOTAL',
          keys: ['HANDLING-FEE', 'HANDLING-VAT'],
        },
      ];
      function parseVatRow(rows, vat, output) {
        const specialFees = fees.filter((f) => rows.indexOf(f.type) > -1);
        const amount = specialFees.reduce((acc, curr) => {
          const total = acc + parseFloat(curr.amount);
          return total;
        }, 0);

        specialFees.forEach((f) => fees.splice(fees.indexOf(f), 1));

        fees.push({
          amount,
          type: output,
          currency: fees[0].currency,
          vat: specialFees.find((f) => f.type === vat),
        });
      }

      vatRows.forEach((c) => parseVatRow(c.keys, c.vat, c.output));

      return fees;
    }),
  },
};
