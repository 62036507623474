var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", [
      _c("p", [_vm._v(_vm._s(_vm.$t("DK_RECEIPT_PAY_BEFORE_DESCRIPTION")))]),
    ]),
    _c(
      "section",
      [
        _vm.item
          ? _c("receipt-summary", {
              attrs: {
                header: _vm.$t("FORSENDELSENR"),
                reference: _vm.item.itemReference,
                sections: _vm.fees,
                "total-fee": _vm.item.totalAmount,
                "paid-at": _vm.item.paidOn,
              },
              model: {
                value: _vm.item,
                callback: function ($$v) {
                  _vm.item = $$v
                },
                expression: "item",
              },
            })
          : _vm._e(),
      ],
      1
    ),
    _c("section", [
      _c("p", [_vm._v(_vm._s(_vm.$t("MAIL_RECEIPT_INFORMATION")))]),
    ]),
    _c(
      "section",
      { staticClass: "links" },
      [
        _c(
          "a",
          {
            attrs: {
              href: `https://portal.postnord.com/tracking/details/${_vm.item.internationalBarCode}`,
              target: "_blank",
              rel: "noopener noreferrer",
            },
          },
          [_vm._v(_vm._s(_vm.$t("TRACK_SHIPMENT")))]
        ),
        _c("router-link", { attrs: { to: { name: "search" } } }, [
          _vm._v(_vm._s(_vm.$t("NEW_SEARCH"))),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }