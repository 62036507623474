<!-- eslint-disable import/no-extraneous-dependencies -->
<script>
import { mapGetters } from 'vuex';
import googleLibphonenumber from 'google-libphonenumber';
import PaymentOptions from '@/components/PaymentOptions.vue';
import PaymentSummaryTable from '@/components/PaymentSummaryTable.vue';
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';

const phoneUtil = googleLibphonenumber.PhoneNumberUtil.getInstance();
const PNF = googleLibphonenumber.PhoneNumberFormat;

export default {
  props: {
    isPaying: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      paymentMethod: {},
      mobileNumber: '',
      errorStatus: undefined,
      attemptSubmit: false,
      attemptSubmitNoPaymentMethod: false,
      isCustoms: null,
      applePayCheckout: null,
      isApplePayMounted: false,
      isImagesModalOpen: false,
    };
  },

  watch: {
    paymentMethod: {
      handler(value) {
        if (value.shouldInitApplePay === true && value.initiatedApplePay !== true) {
          this.initApplePay(value.applePayConfig.paymentProviderConfiguration);
        }

        if (this.paymentMethod.id === 'APPLE-PAY' && this.isApplePayMounted !== true) {
          this.applePayCheckout.mount('#apple-pay-container');
          this.isApplePayMounted = true;
        }

        if (this.paymentMethod.id !== 'APPLE-PAY' && this.isApplePayMounted === true) {
          this.applePayCheckout.unmount('#apple-pay-container');
          this.isApplePayMounted = false;
        }
      },
      deep: true,
    },
  },

  created() {
    if (this.$route.query.error) {
      this.errorStatus = {};
      this.errorStatus.title = this.$route.query.error;
      this.errorStatus.description = 'We have a conflict';
    }

    this.isCustoms = !!this.item.customsId;
  },

  computed: {
    ...mapGetters('shipment', ['item']),
    ...mapGetters('topbar', ['market', 'language']),

    isValid() {
      return this.paymentMethod.id !== null && this.paymentMethod.id !== undefined;
    },

    fees() {
      const fees = [
        {
          name: '',
          rows: [
            {
              type: 'VAT',
              amount: this.getFeeAmount('importVatFee'),
            },
            {
              type: 'HANDLING_FEE_TOTAL',
              amount: `${this.getFeeAmount('handlingWithVatFee')} ${this.item.fee.handlingWithVatFee.currency || 'kr'} ${this.$t('INCLUDING_VAT', { vat: `${this.getFeeAmount('handlingVatFee')} ${this.item.fee.handlingVatFee.currency || 'kr'}` })}`,
            },
          ],
        },
      ];

      if (this.isCustoms) {
        const customsFees = {
          type: 'CUSTOMS_FEE',
          amount: this.getFeeAmount('customsFee'),
        };
        fees[0].rows.splice(1, 0, customsFees);
      }

      return fees;
    },
  },

  methods: {
    initPayment(paymentStateData = undefined) {
      if (!this.isValid) {
        this.attemptSubmitNoPaymentMethod = true;
        return;
      }

      this.errorStatus = undefined;
      this.attemptSubmit = true;
      let phoneNumber;

      if (this.paymentMethod.id === 'SWISH-ECOMMERCE') {
        if (this.invalidPhoneNumber(this.paymentMethod.mobileNumber)) {
          return;
        }

        phoneNumber = phoneUtil.format(phoneUtil.parseAndKeepRawInput(this.paymentMethod.mobileNumber, 'SE'), PNF.E164);
      }

      const data = {
        request: {
          id: this.item.id,
          code: this.item.code,
          customer: {
            email: process.env.VUE_APP_NOPS_EMAIL,
            telephoneNo: phoneNumber,
          },
          platform: 'web',
          paymentMethod: this.paymentMethod.id,
          language: this.language === 'sv' ? 'sv_SE' : 'en_US',
          paymentStateData,
        },
      };
      this.$emit('onInitPayment', data);
    },

    async initApplePay(paymentProviderConfiguration) {
      const configuration = {
        clientKey: paymentProviderConfiguration.clientKey,
        environment: paymentProviderConfiguration.environment,
        paymentMethodsResponse: paymentProviderConfiguration.paymentMethodsResponse,
        locale: this.language === 'sv' ? 'sv_SE' : 'en_US',
        onChange: (state) => {
          if (state.isValid) {
            this.initPayment(state.data);
          }
        },
      };

      const adyenCheckout = await AdyenCheckout(configuration);

      const applePayConfiguration = {
        amount: {
          value: parseFloat(this.item.fee.totalFee.amount.replace(' ', '').replace(',', '.')) * 100,
          currency: 'SEK',
        },
        countryCode: paymentProviderConfiguration.merchantCountryCode,
      };

      this.applePayCheckout = adyenCheckout.create('applepay', applePayConfiguration);
      this.applePayCheckout.isAvailable().then(() => {
        this.paymentMethod.includeApplePayInMethodsList = true;
        this.paymentMethod.initiatedApplePay = true;
      // eslint-disable-next-line no-unused-vars
      }).catch((error) => {
        this.paymentMethod.includeApplePayInMethodsList = false;
        this.paymentMethod.initiatedApplePay = true;
      });
    },

    openModal() {
      this.isImagesModalOpen = true;
    },

    getFeeAmount(type) {
      return this.item.fee[type].amount || 0;
    },

    invalidPhoneNumber(number) {
      if (!number || number.length < 8) {
        return true;
      }

      const phoneNumber = phoneUtil.parseAndKeepRawInput(number, 'SE');

      return !phoneUtil.isValidNumber(phoneNumber) || !phoneUtil.getNumberType(phoneNumber) === 'MOBILE';
    },
  },

  components: {
    PaymentOptions,
    PaymentSummaryTable,
  },
};
</script>

<template>
  <div>
    <pn-toast
      class="prepaid-vat-information"
      v-if="item.iossAcceptedAt || item.pddpAcceptedAt"
      icon="alert-info-circle"
      closable="false"
    >
      <span v-html="$t('CUSTOMS_PAYMENT_PREVIOUS_PREPAID_VAT')" />
    </pn-toast>

    <section v-if="errorStatus">
      <slot />
    </section>

    <slot />

    <section>
      <payment-summary-table
        :header="$t('SHIPMENT_ID')"
        :reference="item.itemId"
        :customs-header="$t('CUSTOMS_ID')"
        :customs-reference="item.customsId"
        :is-customs="isCustoms"
        :sections="fees"
        :total="item.fee.totalFee"
        v-if="item"
      />
    </section>
    <section>
      <div class="shipment-actions">
        <a
          class="link"
          v-if="item.images && item.images.length > 0"
          @click="openModal()"
        >{{ $t('SHOW_IMAGES_OF_PARCEL') }}</a>
      </div>
    </section>

    <section>
      <payment-options
        v-model="paymentMethod"
        :attempt-submit="attemptSubmit"
        :id="item.id"
        :code="item.code" />
      <p class="error-text" v-show="!isValid && attemptSubmitNoPaymentMethod">{{ $t('PAYMENT_OPTION_REQUIRED') }}</p>
    </section>

    <pn-toast
      v-if="isCustoms"
      icon="alert-info-circle"
      closable="false"
    >
      {{ $t('CUSTOMS_PAYMENT_INFO_BOX')}}
    </pn-toast>

    <pn-button
      class='pay-button pn-vat-button'
      v-if="this.paymentMethod.id !== 'APPLE-PAY'"
      :loading="isPaying"
      @click="initPayment()"
    >
      {{ this.paymentMethod.id !== 'INVOICE' ? this.$t('PAY') : this.$t('COMPLETE') }}
    </pn-button>

    <div id="apple-pay-container" />

    <a class="customs-representative" :href="$t('CUSTOMS_REPRESENTATIVE_LINK')" target="_blank" rel='noopener noreferrer' v-if="!isCustoms">
      {{ $t('CUSTOMS_REPRESENTATIVE_LINK_TEXT') }}
    </a>

    <section class="disclaimer">
      <p v-html="$t('DISCLAIMER_FIRST_PAYMENT')" />
      <p v-html="$t('DISCLAIMER_SECOND_PAYMENT')" v-if="!isCustoms" />
    </section>

    <pn-modal
      class='pn-vat-modal'
      :open="this.isImagesModalOpen"
      @close="() => this.isImagesModalOpen = false"
      id="images-modal"
    >
      <h1>{{$t('IMAGES_OF_PARCEL')}}</h1>
      <div class="image" v-for="(image, i) in item.images" :key="i">
        <img :src="image" alt="parcel" />
      </div>
    </pn-modal>
  </div>
</template>

<style lang="scss" scoped>
.pay-button {
  margin-bottom: 1px;
}

.error-text {
  color: $warning;
  font-size: 1.4rem;
  width: 100%;
  margin-left: 0.5em;
  margin-top: 1em;
  margin-bottom: 1em;
}

.customs-representative {
  display: block;
  text-align: center;
  margin: 20px 0 40px;
  text-decoration: underline;
}

.image {
  width: 100%;
  margin-bottom: 20px;
  > img {
    width: 100%;
  }
}
.shipment-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  a {
    margin-top: 1.6rem;

    &:first-of-type {
      margin-top: 0;
    }
  }
}
</style>
