var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("h1", [_vm._v(_vm._s(_vm.$t("YOUR_SHIPMENT_RETURNED")))]),
    _c("section", { staticClass: "id" }, [
      _c("div", [
        _c("span", { staticClass: "info" }, [
          _vm._v(_vm._s(_vm.$t("SHIPMENT_ID"))),
        ]),
        _c("span", [
          _vm._v(_vm._s(_vm.item.internationalBarCode || _vm.item.itemId)),
        ]),
      ]),
    ]),
    _c("section", [_c("p", [_vm._v(_vm._s(_vm.$t("RETURNED_DETAILS")))])]),
    _c(
      "section",
      [
        _c(
          "pn-button",
          {
            staticClass: "pn-vat-button",
            attrs: { appearance: "secondary" },
            on: {
              click: function ($event) {
                return _vm.back()
              },
            },
          },
          [_vm._v(" " + _vm._s(_vm.$t("NEW_SEARCH")) + " ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }