var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("h1", [_vm._v(_vm._s(_vm.$t("PAYMENT_HEADER")))]),
      _c(
        "section",
        [
          _vm.error
            ? _c("error-message", {
                attrs: {
                  title: _vm.error.title,
                  description: _vm.error.description,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(_vm.getComponent(), {
        tag: "component",
        attrs: { "is-paying": _vm.isPaying },
        on: { onInitPayment: _vm.initPayment },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }