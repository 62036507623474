var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("section", [
        _c("p", [_vm._v(_vm._s(_vm.$t("SE_SEARCH_DESCRIPTION")))]),
      ]),
      _c(
        "section",
        { staticClass: "form" },
        _vm._l(_vm.form, function (input, i) {
          return _c(
            "div",
            { key: i, staticClass: "customs-input-container" },
            [
              _c("pn-input", {
                staticClass: "pn-vat-input",
                attrs: {
                  label: _vm.$t(input.placeholder),
                  error:
                    !input.isValid(input.value) && _vm.displayErrors
                      ? _vm.$t(input.errorMessage)
                      : "",
                  type: "text",
                  value: input.value,
                },
                on: {
                  input: function ($event) {
                    input.value = $event.target.value
                  },
                },
              }),
              _c("pn-tooltip", { attrs: { position: _vm.tooltipPosition } }, [
                _vm._v(_vm._s(_vm.$t(input.helpText))),
              ]),
            ],
            1
          )
        }),
        0
      ),
      _c(
        "pn-button",
        {
          staticClass: "pn-vat-button",
          on: {
            click: function ($event) {
              return _vm.submit()
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.$t("SEARCH_BUTTON")) + " ")]
      ),
      _c("section", { staticClass: "disclaimer" }, [
        _c("p", {
          domProps: { innerHTML: _vm._s(_vm.$t("DISCLAIMER_FIRST")) },
        }),
        _c("p", {
          domProps: { innerHTML: _vm._s(_vm.$t("DISCLAIMER_SECOND")) },
        }),
        _c("p", {
          domProps: { innerHTML: _vm._s(_vm.$t("DISCLAIMER_THIRD")) },
        }),
        _c("p", {
          domProps: { innerHTML: _vm._s(_vm.$t("DISCLAIMER_FOURTH")) },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }