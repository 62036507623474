var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "section",
      [
        _vm.item
          ? _c("payment-summary-table", {
              attrs: {
                header: "Forsendelsesnr.",
                reference:
                  _vm.item.internationalBarCode || _vm.item.itemReference,
                sections: _vm.fees,
                total: _vm.item.totalAmount,
              },
            })
          : _vm._e(),
      ],
      1
    ),
    _c(
      "section",
      { staticClass: "payment-options" },
      [
        _c("payment-options", {
          model: {
            value: _vm.paymentMethod,
            callback: function ($$v) {
              _vm.paymentMethod = $$v
            },
            expression: "paymentMethod",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.attemptSubmit && !_vm.paymentMethod,
            expression: "(attemptSubmit && !paymentMethod)",
          },
        ],
        staticClass: "radio-validation-text",
      },
      [_vm._v(_vm._s(_vm.$t("PAY_WITH_ERROR")))]
    ),
    _c(
      "section",
      { staticClass: "receipt" },
      [
        _c("p", { staticClass: "info" }, [
          _vm._v(_vm._s(_vm.$t("PAYMENT_CONTACT_DESCRIPTION"))),
        ]),
        _c("pn-input", {
          staticClass: "pn-vat-input",
          attrs: {
            name: "email",
            label: `${_vm.$t("EMAIL")}`,
            error: _vm.getEmailErrorText()
              ? _vm.$t(_vm.getEmailErrorText())
              : "",
            type: "email",
            autocomplete: "email",
            value: _vm.invoiceInfo.email,
          },
          on: {
            input: function ($event) {
              _vm.invoiceInfo.email = $event.target.value
            },
          },
        }),
        _c("pn-input", {
          staticClass: "pn-vat-input",
          attrs: {
            name: "phone",
            label: `${_vm.$t("PHONE_NUMBER")} (${_vm.$t("DANISH")})`,
            placeholder: _vm.$t("PHONE_NUMBER_PLACEHOLDER"),
            error: _vm.getPhoneErrorText()
              ? _vm.$t(_vm.getPhoneErrorText())
              : "",
            type: "tel",
            autocomplete: "tel",
            value: _vm.invoiceInfo.telephone,
          },
          on: {
            input: function ($event) {
              _vm.invoiceInfo.telephone = $event.target.value
            },
          },
        }),
      ],
      1
    ),
    _c(
      "section",
      [
        _c(
          "pn-button",
          {
            staticClass: "pn-vat-button",
            attrs: { loading: _vm.isPaying },
            on: {
              click: function ($event) {
                return _vm.validateForm()
              },
            },
          },
          [_vm._v(" " + _vm._s(_vm.$t("PAY")) + " ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }