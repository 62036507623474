var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "topbar-container", attrs: { id: "topbar-market-web" } },
    [
      _vm.market && _vm.language
        ? [
            _c("pn-marketweb-siteheader", {
              attrs: {
                endpoint: _vm.ENDPOINT,
                environment: _vm.ENVIRONMENT,
                "spa-mode": "true",
                market: _vm.market.toLowerCase(),
                language: _vm.language,
                "site-domain-in-urls": "true",
                "hide-site-selector": _vm.market === "DK",
              },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }