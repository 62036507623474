<script>
import googleLibphonenumber from 'google-libphonenumber';
import { mapGetters } from 'vuex';
import PaymentOptions from '@/components/PaymentOptions.vue';
import PaymentSummaryTable from '@/components/PaymentSummaryTable.vue';

export default {
  name: 'dk-payment',
  props: {
    isPaying: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      paymentMethod: null,
      mobileNumber: '',
      errorStatus: undefined,
      invoiceInfo: {
        email: '',
        telephone: '',
      },
      attemptSubmit: false,
    };
  },

  computed: {
    ...mapGetters('shipment', ['item']),
    ...mapGetters('topbar', ['market', 'language']),
    isValid() {
      return this.paymentMethod !== null && this.invoiceInfo.email.length > 0 && this.invoiceInfo.telephone.length > 6;
    },
    validateTelephone() {
      const phoneUtil = googleLibphonenumber.PhoneNumberUtil.getInstance();
      const number = this.invoiceInfo.telephone.toString();

      return number.length === 8 && phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(number, 'DK'));
    },
    validateEmail() {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(this.invoiceInfo.email).toLowerCase());
    },
    fees() {
      return [
        {
          name: 'SKAT',
          rows: [
            {
              type: 'CUSTOMS',
              amount: this.getFeeAmount('CUSTOMS'),
            },
            {
              type: 'VAT',
              amount: this.getFeeAmount('VAT'),
            },
          ],
        },
        {
          name: 'PostNord',
          rows: [
            {
              type: 'HANDLING_FEE',
              amount: this.getFeeAmount('HANDLING-FEE'),
            },
            {
              type: 'HANDLING_VAT',
              amount: this.getFeeAmount('HANDLING-VAT'),
            },
          ],
        },
      ];
    },
  },
  methods: {
    getFeeAmount(type) {
      const fee = this.item.fee.find((f) => f.type === type);
      if (!fee) {
        return 0;
      }

      return fee.amount;
    },
    initPayment() {
      this.errorStatus = undefined;

      const data = {
        request: {
          currencyCode: 'DKK',
          clientId: process.env.VUE_APP_NOPS_DK_CLIENT_ID,
          paymentMethod: this.paymentMethod.id,
          countryCode: 'DK',
          language: this.language === 'da' ? 'da_DK' : 'en_US',
          channel: 'web',
          platform: 'web',
          customer: {
            email: this.invoiceInfo.email,
            telephoneNo: this.invoiceInfo.telephone.slice(-8),
          },
        },
      };

      this.$emit('onInitPayment', data);
    },
    validateForm() {
      this.attemptSubmit = true;

      if (!this.isValid || !this.validateEmail || !this.validateTelephone) {
        return;
      }

      this.initPayment();
    },

    getEmailErrorText() {
      if (this.attemptSubmit) {
        if (!this.invoiceInfo.email) {
          return 'EMAIL_REQUIRED';
        }

        if (!this.validateEmail) {
          return 'EMAIL_ERROR';
        }
      }

      return '';
    },

    getPhoneErrorText() {
      if (this.attemptSubmit) {
        if (!this.invoiceInfo.telephone) {
          return 'PHONE_NUMBER_REQUIRED';
        }

        if (!this.validateTelephone) {
          return 'PHONE_NUMBER_ERROR';
        }
      }

      return '';
    },
  },

  components: {
    PaymentOptions,
    PaymentSummaryTable,
  },
};

</script>

<template>
  <div>
    <section>
      <payment-summary-table
        header="Forsendelsesnr."
        :reference="item.internationalBarCode || item.itemReference"
        :sections="fees"
        :total="item.totalAmount"
        v-if="item"
      />
    </section>

    <section class="payment-options">
      <payment-options v-model="paymentMethod" />
    </section>
    <div class="radio-validation-text" v-show="(attemptSubmit && !paymentMethod)">{{ $t('PAY_WITH_ERROR') }}</div>

    <section class="receipt">
      <p class="info">{{$t('PAYMENT_CONTACT_DESCRIPTION')}}</p>
      <pn-input
        class="pn-vat-input"
        name="email"
        :label="`${$t('EMAIL')}`"
        :error="getEmailErrorText() ? $t(getEmailErrorText()) : ''"
        type="email"
        autocomplete="email"
        :value="invoiceInfo.email"
        @input="invoiceInfo.email = $event.target.value"
      />

      <pn-input
        class="pn-vat-input"
        name="phone"
        :label="`${$t('PHONE_NUMBER')} (${$t('DANISH')})`"
        :placeholder="$t('PHONE_NUMBER_PLACEHOLDER')"
        :error="getPhoneErrorText() ? $t(getPhoneErrorText()) : ''"
        type="tel"
        autocomplete="tel"
        :value="invoiceInfo.telephone"
        @input="invoiceInfo.telephone = $event.target.value"
      />
    </section>

    <section>
      <pn-button
        class='pn-vat-button'
        :loading="isPaying"
        @click="validateForm()"
      >
        {{ $t('PAY') }}
      </pn-button>
    </section>
  </div>
</template>

<style lang="scss">
  section {
    margin-top: 2em;

    > * {
      margin-bottom: 1em;
    }
  }

  .radio-validation-text {
    color: $warning;
    font-size: 14px;
    margin-top: 8px;
    @media screen and (max-width: 464px) {
      padding: 0 1.6rem;
    }
  }
</style>
