var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      this.market.toLowerCase() === "se"
        ? _c("h1", [_vm._v(_vm._s(_vm.$t("SEARCH_HEADER_SE")))])
        : _vm._e(),
      this.market.toLowerCase() === "dk"
        ? _c("h1", [_vm._v(_vm._s(_vm.$t("SEARCH_HEADER_DK")))])
        : _vm._e(),
      _vm.error
        ? _c(
            "section",
            [
              _c("error-message", {
                attrs: {
                  title: _vm.error.title,
                  description: _vm.error.description,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(_vm.getComponent(), {
        tag: "component",
        on: { onSearch: _vm.search },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }