<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters([
      'loaderTitle',
      'loaderDescription',
    ]),
  },
};
</script>

<template>
  <div class="container">
    <div class="spinner">
      <pn-spinner />
    </div>
    <h2 v-if="loaderTitle">{{ this.$t(loaderTitle) }}</h2>
    <p v-if="loaderDescription">{{ this.$t(loaderDescription) }}</p>
  </div>
</template>

<style lang="scss" scoped>
  .container {
    max-width: 464px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 6.4rem 0;
    text-align: center;

    .spinner {
      background-color: $blue50;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 0 1.6rem;
    }

    h2 {
      margin-bottom: 0.5;
    }

    p {
      color: $gray700;
    }
  }
</style>
