<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    header: {
      type: String,
      required: true,
    },

    reference: {
      type: String,
      required: true,
    },

    customsHeader: {
      type: String,
      required: false,
      default: '',
    },

    customsReference: {
      type: String,
      required: false,
      default: '',
    },

    isCustoms: {
      type: Boolean,
      required: false,
      default: false,
    },

    isCostPreliminary: {
      type: Boolean,
      required: false,
      default: false,
    },

    sections: {
      type: Array,
      required: true,
    },

    total: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('topbar', ['market']),
  },
  methods: {
    format(val) {
      if (typeof val === 'string') {
        val = parseFloat(val.replace(' ', '').replace(',', '.'));
      }
      return `${val.toFixed(2).replace('.', ',')} ${this.total.currency}`;
    },

    sectionTotal(section) {
      let val;
      if (this.market === 'DK') {
        val = section.rows.reduce((sum, row) => sum + row.amount, 0);
      } else {
        val = section.rows.reduce((sum, row) => sum + parseFloat(row.amount.toString().replace(' ', '').replace(',', '.')), 0);
      }
      return this.format(val);
    },
  },
};
</script>

<template>
  <div class="payment-summary">
    <div class="payment-header">
      <div class="row">
        <p class="bold">{{ header }}</p>
        <p>{{ reference }}</p>
      </div>
      <div class="row" v-if="isCustoms">
        <p class="bold">{{ customsHeader }}</p>
        <p>{{ customsReference }}</p>
      </div>
    </div>

    <table :class="{ customs: isCustoms }">
      <tbody v-for="(section, i) in sections" :key="i">
        <tr colspan="2" v-if="sections.length > 1">
          <td class="header">{{ $t('AMOUNT_TO', { name: section.name }) }}</td>
        </tr>
        <tr v-for="(row, j) in section.rows" :key="j">
          <td>{{ $t(row.type) }}</td>
          <td v-if="row.type !== 'HANDLING_FEE_TOTAL'">
            {{ format(row.amount) }}
          </td>
          <td v-if="row.type === 'HANDLING_FEE_TOTAL'">{{ row.amount }}</td>
        </tr>
        <tr class="footer" v-if="sections.length > 1">
          <td v-if="section.name">{{ $t('AMOUNT_TO_TOTAL', { name: section.name }) }}</td>
          <td v-else />
          <td>{{ sectionTotal(section) }}</td>
        </tr>
      </tbody>
    </table>

    <div class="payment-sum row">
      <p class="bold">{{ isCostPreliminary ? $t('PRELIMINARY_AMOUNT_TO_PAY') : $t('AMOUNT_TO_PAY') }}</p>
      <p class="total">
        <strong>{{ total.amount }}</strong>
        <span class="currency">{{ total.currency }}</span>
        <span class="asterisk" v-if="isCostPreliminary">{{ $t('ASTERISK') }}</span>
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .payment-summary {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .payment-header {
      border-bottom: 4px solid $blue700;
      margin-bottom: 16px;
      height: auto;
    }

    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .payment-sum {
      align-items: baseline;
      margin-top: 1.6rem;

      p {
        margin: 0;
        &:first-child {
          font-weight: $bold;
        }
      }

      .total {
        font-size: 2em;
        text-align: right;

        strong {
          word-break: break-all;
          font-weight: $bold;
        }
      }

      .currency {
        margin-left: 6px;
      }
    }

    .bold {
      font-weight: $bold;
    }
  }

  table {
    border-spacing: 0;
    width: 100%;
    border-bottom: 1px solid $gray200;

    &.customs {
      td {
        line-height: 32px;
      }

      tr:nth-last-child(2) > td {
        padding: 0;
      }
    }

    tr {
      &:nth-last-child(2) {
        > td {
          padding: 0 0 16px;

          @media #{$phone} {
            padding: 0 0 .8rem;
          }
        }
      }

      &:last-child {
        > td  {
          padding: 0 0 20px;

          @media #{$phone} {
            padding: 0 0 1.6rem;

            &:last-child {
              width: 175px;
            }
          }
        }
      }

      &.footer {
        > td {
          border-top: 1px solid $gray50;
          padding: 16px 0 32px;
        }
      }
    }

    td {
      line-height: 24px;
      vertical-align: top;

      &:last-child {
        text-align: right;
      }

      &.header {
        font-weight: $bold;
        text-align: left;
      }
    }
  }
</style>
