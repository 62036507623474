<script>
import { mapActions, mapGetters } from 'vuex';
import Axios from 'axios';
import ErrorMessage from '@/components/ErrorMessage.vue';

export default {
  data() {
    return {
      error: null,
      isPaying: false,
      paymentMethod: null,
      isCustoms: null,
      isImagesModalOpen: false,
    };
  },

  created() {
    this.getPaymentMethod();

    // If component is loaded with error in query it means we've been
    // redirected from the redirect component and should display the error
    const qError = this.$route.query.error;
    if (qError) {
      this.handleError(qError);
    }
  },

  computed: {
    ...mapGetters('topbar', ['language']),
    ...mapGetters('shipment', ['item']),
  },

  methods: {
    ...mapActions('shipment', ['requestPaymentV2']),

    handleError() {
      this.error = {};
      this.error.title = this.$t('PREPAID_VAT_FAILED');
      this.error.description = this.$t('PREPAID_VAT_FAILED_DESCRIPTION');
      window.scrollTo(0, 0);
    },

    openModal() {
      this.isImagesModalOpen = true;
    },

    async getPaymentMethod() {
      const url = `${process.env.VUE_APP_API_V2_BASE_URL}/items/${this.item.id}/${this.item.code}/payment-methods`;

      const { data } = await Axios.get(url);
      this.paymentMethod = data.find((pm) => ['IOSS', 'PDDP'].includes(pm.method)).method;
    },

    async initPayment() {
      this.isPaying = true;

      const { id, code, itemId } = this.item;

      const request = {
        id,
        code,
        customer: {
          email: process.env.VUE_APP_NOPS_EMAIL,
        },
        platform: 'web',
        paymentMethod: this.paymentMethod,
        redirectUrl: this.getRedirectUrl(),
        language: this.language === 'sv' ? 'sv_SE' : 'en_US',
      };

      try {
        await this.requestPaymentV2({ request, item: { id: itemId, code } });
        this.isPaying = false;
        this.$router.push({ name: 'confirmation-prepaid-vat', query: { ...this.$route.query } });
      } catch (err) {
        this.isPaying = false;
        this.handleError(err);
        window.scrollTo(0, 0);
      }
    },

    getRedirectUrl() {
      const { code } = this.item;
      const baseUrl = process.env.VUE_APP_SE_BASE_URL;

      const redirectUrl = [
        window.location.protocol,
        '//',
        window.location.host,
        `/${baseUrl}`,
        'payment-redirect',
        `?itemId=${this.item.itemId}`,
        `&code=${code}`,
      ];

      return redirectUrl.join('');
    },
  },

  components: {
    ErrorMessage,
  },
};
</script>

<template>
  <div class="container">
    <h1>{{ $t('ACCEPT_HEADER') }}</h1>

    <section v-if="error">
      <error-message :title="error.title" :description="error.description" />
    </section>

    <section class="information">
      <p v-html="$t('ACCEPT_INFORMATION')" />
    </section>

    <section class="shipment-information">
      <div class="row">
        <p class="bold">{{ $t('SHIPMENT_ID') }}</p>
        <p>{{ item.itemId }}</p>
      </div>
    </section>
    <section>
      <div class="shipment-actions">
        <a
          class="link"
          v-if="item.images && item.images.length > 0"
          @click="openModal()"
        >{{ $t('SHOW_IMAGES_OF_PARCEL') }}</a>
      </div>
    </section>

    <pn-button
      class='pn-vat-button'
      :loading="isPaying"
      @click="initPayment()"
    >
      {{ $t('ACCEPT') }}
    </pn-button>

    <a class="customs-representative" :href="$t('CUSTOMS_REPRESENTATIVE_LINK')" target="_blank" rel='noopener noreferrer'>
      {{ $t('CUSTOMS_REPRESENTATIVE_LINK_TEXT') }}
    </a>

    <pn-modal
      class='pn-vat-modal'
      :open="this.isImagesModalOpen"
      @close="() => this.isImagesModalOpen = false"
    >
      <h1>{{$t('IMAGES_OF_PARCEL')}}</h1>
      <div class="image" v-for="(image, i) in item.images" :key="i">
        <img :src="image" alt="parcel" />
      </div>
    </pn-modal>

  </div>
</template>

<style lang="scss" scoped>
  .title {
    font-size: 3em;
    font-weight: $bold;
    display: block;
    margin-top: 0;
    margin-bottom: 3.2rem;
  }

  .shipment-information {
    border-bottom: 4px solid $blue700;
    margin-bottom: 16px;
    height: auto;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .bold {
    font-weight: $bold;
  }

  .information {
    margin-bottom: 3.2rem;
  }

  .customs-representative {
    display: block;
    text-align: center;
    margin: 20px 0 40px;
    text-decoration: underline;
  }

  .image {
    width: 100%;
    margin-bottom: 20px;

    > img {
      width: 100%;
    }
  }
</style>
