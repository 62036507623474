var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("h1", [_vm._v(_vm._s(_vm.$t("CUSTOMS_DECLARATION_INITIATED_HEADER")))]),
    _c("p", [
      _vm._v(" " + _vm._s(_vm.$t("CUSTOMS_DECLARATION_INITIATED_TEXT")) + " "),
    ]),
    _c("p", [
      _vm._v(" " + _vm._s(_vm.$t("CUSTOMS_DECLARATION_CREDIT_CUSTOMER")) + " "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }