<script>
/* eslint-disable no-param-reassign */
import { mapGetters } from 'vuex';
import ErrorMessage from '@/components/ErrorMessage.vue';
import DkSearch from './DkSearch.vue';
import SeSearch from './SeSearch.vue';

export default ({
  data() {
    console.log('search vue');
    return {
      errorStatus: null,
      error: null,
      errors: {
        'MaxItemPayableWindowExceededException': {
          title: this.$t('SEARCH_SHIPMENT_EXPIRED'),
          description: this.$t('SEARCH_SHIPMENT_EXPIRED_DESCRIPTION'),
        },
        'ResourceNotFoundException': {
          title: this.$t('SEARCH_NOT_FOUND_TITLE'),
          description: this.$t('SEARCH_NOT_FOUND_DESCRIPTION'),
        },
        'ResourceNotFound': {
          title: this.$t('SEARCH_NOT_FOUND_TITLE'),
          description: this.$t('SEARCH_NOT_FOUND_DESCRIPTION'),
        },
        'ValidationException': {
          title: this.$t('SEARCH_NOT_FOUND_TITLE'),
          description: this.$t('SEARCH_NOT_FOUND_DESCRIPTION'),
        },
        'ValidationError': {
          title: this.$t('SEARCH_NOT_FOUND_TITLE'),
          description: this.$t('SEARCH_NOT_FOUND_DESCRIPTION'),
        },
        'TypeError': {
          title: this.$t('SEARCH_NOT_FOUND_TITLE'),
          description: this.$t('SEARCH_NOT_FOUND_DESCRIPTION'),
        },
        'ExternalAPIClientException': {
          title: this.$t('SEARCH_NOT_FOUND_TITLE'),
          description: this.$t('SEARCH_NOT_FOUND_DESCRIPTION'),
        },
        'Error': {
          title: this.$t('SEARCH_NOT_FOUND_TITLE'),
          description: this.$t('SEARCH_NOT_FOUND_DESCRIPTION'),
        },
        'APIERROR': {
          title: this.$t('RETURNED'),
          description: this.$t('RETURNED_DESCRIPTION'),
        },
        'RETURNED': {
          title: this.$t('RETURNED'),
          description: this.$t('RETURNED_DESCRIPTION'),
        },
      },
    };
  },

  computed: {
    ...mapGetters('topbar', ['market']),
  },

  methods: {
    search(data) {
      console.log('seeearch');
      console.log(data);

      this.errorStatus = null;
      this.$store.commit('SET_IS_LOADING', { title: 'SEARCHING' });

      // TODO: Add to query even if search fails
      // this.$route.query = { itemId: this.form[0].value, postalCode: this.form[1].value };
      const action = this.market === 'SE' ? 'shipment/getV2' : 'shipment/get';

      console.log('action', action);
      this.$store.dispatch(action, data)
        .then((res) => {
          console.log('reeessss');
          console.log(res);
          if (res.item.importRule) {
            res.item.status += `_${res.item.importRule.toUpperCase()}`;
          }

          this.$store.commit('SET_DONE_LOADING');
          this.handleRedirect(res.item.status, this.market.toLowerCase(), data);
        })
        .catch((err) => {
          console.log('errrrrrooorr');
          console.log(err);
          this.$router.replace({ name: 'search', query: { itemId: data.itemId, code: data.code } });

          const faultCode = err.response?.data?.compositeFault?.faults?.[0]?.faultCode;
          const errorCode = err.response?.data?.compositeFault?.faults?.[0]?.errorCode;
          const errorStatus = errorCode ?? faultCode;
          console.log('Error Status: ', errorStatus);
          this.error = this.errors[errorStatus] || this.errors.Error;
          console.log('this error: ', this.error);

          this.$store.commit('SET_DONE_LOADING');
        });
    },

    handleRedirect(status, market, { itemId, code }) {
      // Some statuses have hard redirects, e.g. if the packaged is paid
      // customer should always be redirected to receipt
      const statusRedirects = {
        'dk': {
          'DONE': 'receipt',
          'EXPIRED': 'shipment',
          'RETURNED': 'returned',
          'ANNULLED': 'annulled',
          'WAITING_FOR_PAYMENT': 'payment',
          'DEFAULT': 'shipment',
        },
        'se': {
          'DONE': 'receipt',
          'INVOICE_DONE': 'invoice',
          'INVOICE_PAID': 'invoice-paid',
          'EXPIRED': 'search',
          'DEFAULT': 'payment',
          'CUSTOMS_INITIAL': 'customs-shipment',
          'CUSTOMS_PENDING': 'customs-declaration',
          'CUSTOMS_DELAYED': 'customs-declaration-delayed',
          'OPEN': 'payment',
          'OPEN_IOSS': 'accept',
          'OPEN_PDDP': 'accept',
          'DONE_IOSS': 'confirmation-prepaid-vat',
          'DONE_PDDP': 'confirmation-prepaid-vat',
        },
      };

      if (statusRedirects[market][status]) {
        this.$router.push({ name: statusRedirects[market][status], query: { itemId, code } });
      } else {
        // If no hard redirects are found, check if a callback is specified in the queryParams
        // otherwise just default to next stage
        const dest = this.$route.query.cb || statusRedirects[market].DEFAULT;

        const query = Object.assign(this.$route.query, { itemId, code });
        delete query.cb;

        this.$router.push({ name: dest, query });
      }
    },

    getComponent() {
      return `${this.market.toLowerCase()}-search`;
    },
  },

  components: {
    DkSearch,
    SeSearch,
    ErrorMessage,
  },
});
</script>

<template>
  <div class="container">
    <h1 v-if="this.market.toLowerCase() === 'se'">{{ $t('SEARCH_HEADER_SE') }}</h1>
    <h1 v-if="this.market.toLowerCase() === 'dk'">{{ $t('SEARCH_HEADER_DK') }}</h1>

    <section v-if="error">
      <error-message :title="error.title" :description="error.description" />
    </section>

    <component :is="getComponent()" @onSearch="search" />
  </div>
</template>

<style lang="scss" scoped>
  pn-input {
    display: block;
    margin-bottom: 1em;

    &:last-of-type {
      margin-bottom: 64px;
    }
  }

  .description {
    color: $gray900;
    margin-bottom: 2em;
  }
</style>
