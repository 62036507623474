var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("h1", [_vm._v(_vm._s(_vm.$t("ACCEPT_HEADER")))]),
      _vm.error
        ? _c(
            "section",
            [
              _c("error-message", {
                attrs: {
                  title: _vm.error.title,
                  description: _vm.error.description,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("section", { staticClass: "information" }, [
        _c("p", {
          domProps: { innerHTML: _vm._s(_vm.$t("ACCEPT_INFORMATION")) },
        }),
      ]),
      _c("section", { staticClass: "shipment-information" }, [
        _c("div", { staticClass: "row" }, [
          _c("p", { staticClass: "bold" }, [
            _vm._v(_vm._s(_vm.$t("SHIPMENT_ID"))),
          ]),
          _c("p", [_vm._v(_vm._s(_vm.item.itemId))]),
        ]),
      ]),
      _c("section", [
        _c("div", { staticClass: "shipment-actions" }, [
          _vm.item.images && _vm.item.images.length > 0
            ? _c(
                "a",
                {
                  staticClass: "link",
                  on: {
                    click: function ($event) {
                      return _vm.openModal()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("SHOW_IMAGES_OF_PARCEL")))]
              )
            : _vm._e(),
        ]),
      ]),
      _c(
        "pn-button",
        {
          staticClass: "pn-vat-button",
          attrs: { loading: _vm.isPaying },
          on: {
            click: function ($event) {
              return _vm.initPayment()
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.$t("ACCEPT")) + " ")]
      ),
      _c(
        "a",
        {
          staticClass: "customs-representative",
          attrs: {
            href: _vm.$t("CUSTOMS_REPRESENTATIVE_LINK"),
            target: "_blank",
            rel: "noopener noreferrer",
          },
        },
        [_vm._v(" " + _vm._s(_vm.$t("CUSTOMS_REPRESENTATIVE_LINK_TEXT")) + " ")]
      ),
      _c(
        "pn-modal",
        {
          staticClass: "pn-vat-modal",
          attrs: { open: this.isImagesModalOpen },
          on: { close: () => (this.isImagesModalOpen = false) },
        },
        [
          _c("h1", [_vm._v(_vm._s(_vm.$t("IMAGES_OF_PARCEL")))]),
          _vm._l(_vm.item.images, function (image, i) {
            return _c("div", { key: i, staticClass: "image" }, [
              _c("img", { attrs: { src: image, alt: "parcel" } }),
            ])
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }