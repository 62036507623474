<script>
import { mapGetters } from 'vuex';

const ENDPOINT = process.env.VUE_APP_TOPBAR_MARKETWEB_URL;
const ENVIRONMENT = process.env.VUE_APP_TOPBAR_MARKETWEB_ENVIRONMENT;

export default ({
  data() {
    return {
      ENDPOINT,
      ENVIRONMENT,
    };
  },

  computed: {
    ...mapGetters('topbar', ['market', 'language']),
  },

  mounted() {
    this.$refs['topbar-container'].addEventListener('changeLanguage', this.onLanguageChanged);
    this.onLoaded();
  },

  methods: {
    onLoaded() {
      this.$emit('onLoaded');
    },

    onLanguageChanged(e) {
      this.$emit('onLanguageChanged', e.detail);
    },
  },
});
</script>

<template>
  <div id="topbar-market-web" ref="topbar-container">
    <template v-if="market && language">
      <pn-marketweb-siteheader
        :endpoint="ENDPOINT"
        :environment="ENVIRONMENT"
        spa-mode="true"
        :market="market.toLowerCase()"
        :language="language"
        site-domain-in-urls="true"
        :hide-site-selector="market === 'DK'"
      />
    </template>
  </div>
</template>

<style lang="scss">
</style>
