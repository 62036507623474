<script>
import { mapGetters } from 'vuex';
import ErrorMessage from '@/components/ErrorMessage.vue';
import PaymentSummaryTable from '@/components/PaymentSummaryTable.vue';
import Tile from '@/components/Tile.vue';

export default {

  data() {
    return {
      emailIsValid: false,
      termsAccepted: false,
      rejectEmail: '',
      isLoading: false,
      errors: {
        EXPIRED: {
          title: this.$t('EXPIRED'),
          description: this.$t('EXPIRED_DESCRIPTION'),
        },
        RETURNED: {
          title: this.$t('RETURNED'),
          description: this.$t('RETURNED_DESCRIPTION'),
        },
      },
      attemptSubmit: false,
      attemptReject: false,
      isRejectModalOpen: false,
    };
  },
  computed: {
    ...mapGetters('shipment', [
      'item',
    ]),
    ...mapGetters('topbar', [
      'market',
    ]),
    fees() {
      return [
        {
          name: 'SKAT',
          rows: [
            {
              type: 'CUSTOMS',
              amount: this.getFeeAmount('CUSTOMS'),
            },
            {
              type: 'VAT',
              amount: this.getFeeAmount('VAT'),
            },
          ],
        },
        {
          name: 'PostNord',
          rows: [
            {
              type: 'HANDLING_FEE',
              amount: this.getFeeAmount('HANDLING-FEE'),
            },
            {
              type: 'HANDLING_VAT',
              amount: this.getFeeAmount('HANDLING-VAT'),
            },
          ],
        },
      ];
    },
    validateEmail() {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(this.rejectEmail).toLowerCase());
    },
    isInactive() {
      return ['RETURNED', 'EXPIRED'].indexOf(this.item.status) > -1;
    },
  },
  methods: {
    submit() {
      this.attemptSubmit = true;

      if (!this.termsAccepted) {
        return;
      }

      this.$router.push({ name: 'payment', query: this.$route.query });
    },
    back() {
      this.$router.push({ name: 'search' });
    },
    openModal() {
      this.attemptSubmit = true;

      if (this.isInactive || !this.termsAccepted) {
        return;
      }

      this.isRejectModalOpen = true;
    },
    closeModal() {
      this.isRejectModalOpen = false;
    },
    getFeeAmount(type) {
      const fee = this.item.fee.find((f) => f.type === type);
      if (!fee) {
        return 0;
      }

      return fee.amount;
    },
    confirmReject() {
      this.attemptReject = true;

      if (this.validateEmail) {
        this.isLoading = true;

        this.$store.dispatch('shipment/reject', {
          email: this.rejectEmail,
        })
          .then(() => {
            this.isLoading = false;
            this.closeModal();
            this.$router.push({ name: 'search', query: this.$route.query });
          })
          .catch(console.error);
      }
    },
  },
  components: {
    ErrorMessage,
    PaymentSummaryTable,
    Tile,
  },
};
</script>

<template>
  <div class="container">
    <Tile
      v-if="market === 'DK'"
      class="download-app-tile"
      link="https://app.postnord.com/download/"
      illustration="hand-app-map"
      :heading="$t('DOWNLOAD_APP_TILE_TITLE')"
      :content="$t('DOWNLOAD_APP_TILE_CONTENT')"
    />

    <h1>{{$t('YOUR_SHIPMENT')}}</h1>

    <p class="info">
      {{ $t('CURRENT_INFO_TEXT') }}
      (<a :href="`https://portal.postnord.com/tracking/details/${item.internationalBarCode}`" target="_blank" rel='noopener noreferrer'>{{ $t('TRACK_AND_TRACE') }}</a>):
    </p>
    <section v-if="isInactive">
      <error-message :title="errors[item.status].title" :description="errors[item.status].description" />
    </section>

    <section v-if="!isInactive">
      <payment-summary-table
        :header="$t('SHIPMENT_ID')"
        :reference="item.internationalBarCode || item.itemReference"
        :sections="fees"
        :total="item.totalAmount"
        v-if="item" />
    </section>

    <section v-if="isInactive">
      <pn-button
        class='pn-vat-button'
        appearance="secondary"
        @click="back()"
      >
        {{ $t('BACK') }}
      </pn-button>
    </section>

    <section v-if="!isInactive">
      <span v-html="$t('ACCEPT_REJECT_PACKAGE_INFORMATION')" />

      <pn-tooltip>
        {{ $t('ACCEPT_REJECT_PACKAGE_INFORMATION_DETAILS') }}
      </pn-tooltip>
    </section>

    <section v-if="!isInactive" class="accept-terms">
      <pn-checkbox
        name="accept-terms"
        checkboxid="accept-terms"
        value="test"
        @change="termsAccepted = $event.target.checked"
        :checked="termsAccepted"
      />
      <div>
        <label for="accept-terms">{{ $t('I_ACCEPT_TERMS') }}</label>
        <a :href="$t('TERMS_AND_CONDITION_LINK')" target="_blank" rel="noopener noreferrer"> {{$t('SHIPMENT_TERMS')}}</a>
      </div>
    </section>
    <div class="check-validation-text" v-show="attemptSubmit && !termsAccepted">{{ $t('I_ACCEPT_TERMS_ERROR') }}</div>

    <div v-if="!isInactive">
      <section>
        <pn-button
          class='pn-vat-button'
          @click="submit()"
        >
          {{ $t('ACCEPT') }}
        </pn-button>

        <div class="reject-btn">
          <pn-button
            class='pn-vat-button'
            appearance="secondary"
            @click="openModal()"
          >
            {{ $t('REJECT_SHIPMENT') }}
          </pn-button>
        </div>
      </section>

      <div class="docs-row-section">
        <div class="docs-row">
          <pn-modal
            class='pn-vat-modal'
            :open="this.isRejectModalOpen"
            @close="() => this.isRejectModalOpen = false"
          >
            <div class="pn-modal-content">
              <h2>{{ $t('REJECT_SHIPMENT') }}</h2>

              <section>
                <p>{{$t('REJECT_SHIPMENT_CONFIRMATION')}}</p>
              </section>

              <p>{{$t('SHIPMENT_MODAL_EMAIL_DESCRIPTION')}}</p>
              <pn-input
                class='pn-vat-input'
                :label="$t('EMAIL')"
                :error="attemptReject && !validateEmail ? $t('EMAIL_ERROR') : ''"
                :value="rejectEmail"
                @input="rejectEmail = $event.target.value"
              />

              <section>
                <pn-button
                  class='pn-vat-button'
                  :loading="isLoading"
                  @click="confirmReject()"
                >
                  {{$t('SHIPMENT_MODAL_CONFIRM')}}
                </pn-button>

                <pn-button
                  class='pn-vat-button'
                  appearance="secondary"
                  @click="closeModal()"
                >
                  {{ $t('SHIPMENT_MODAL_ABORT') }}
                </pn-button>
              </section>
            </div>
          </pn-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

  .pn-modal-content {
    margin: 0 auto;
    max-width: 464px;

    @media #{$phone} {
      padding: 3.2rem !important;
    }
  }

  .accept-terms {
    margin: 2.4rem 0 0;
    height: 6.4rem;
    position: relative;

    a {
      word-break: break-word;
    }

    > div {
      display: inline-block;
      position: absolute;
      top: 2px;
      left: 3.2rem;
    }
  }

  pn-checkbox {
    align-items: center;
    font-size: 16px;
    display: inline-block;
    position: absolute;
  }

  .info {
    margin: 3.2rem 0;
  }

  p {
    ::v-deep span {
      display: block;
      font-size: 14px;
      margin: 20px 0 0;
    }
  }

  .check-validation-text {
    color: $warning;
    font-size: 14px;
    margin-top: 8px;

    @media (max-width: 880px) {
      padding: 0 1.6rem;
    }
  }
.download-app-tile {
  margin-bottom: 4.8rem;

  @media #{$phone} {
    margin-bottom: 3.2rem;
  }
}
</style>
