<script>
export default ({
  name: 'CustomsDeclarationDelayed',

});
</script>

<template>
  <div class="container">
    <h1>{{ $t('CUSTOMS_DECLARATION_DELAYED_HEADER') }}</h1>
    <p> {{ $t('CUSTOMS_DECLARATION_DELAYED_TEXT') }} </p>
    <p> {{ $t('CUSTOMS_DECLARATION_CREDIT_CUSTOMER') }} </p>
  </div>
</template>

<style lang="scss" scoped>
p {
  margin-bottom: 20px;

  @media #{$phone} {
    padding: 0 16px;
  }
}

</style>
