<script>
import { mapGetters } from 'vuex';
import googleLibphonenumber from 'google-libphonenumber';
import PaymentSummaryTable from '@/components/PaymentSummaryTable.vue';

const phoneUtil = googleLibphonenumber.PhoneNumberUtil.getInstance();
const PNF = googleLibphonenumber.PhoneNumberFormat;

export default {

  data() {
    return {
      isLoading: false,
      isInvoiceRequested: false,
      email: '',
      phoneNumber: '',
      formattedPhoneNumber: '',
      attemptSubmit: false,
      isPhoneNumberValid: null,
      isEmailValid: null,
      isAccepted: false,
      error: null,
      isImagesModalOpen: false,
    };
  },

  computed: {
    ...mapGetters('shipment', [
      'item',
    ]),

    fees() {
      const fees = [{
        rows: [
          {
            type: 'VAT',
            amount: this.getFeeAmount('importVatFee'),
          },
          {
            type: 'CUSTOMS_FEE',
            amount: this.getFeeAmount('customsFee'),
          },
          {
            type: 'HANDLING_FEE_TOTAL',
            amount: `${this.getFeeAmount('handlingWithVatFee')} ${this.item.fee.handlingWithVatFee.currency || 'kr'} ${this.$t('INCLUDING_VAT', { vat: `${this.getFeeAmount('handlingVatFee')} ${this.item.fee.handlingVatFee.currency || 'kr'}` })}`,
          },
        ],
      }];

      return fees;
    },
  },

  components: {
    PaymentSummaryTable,
  },

  created() {
    this.phoneNumber = this.item.consignee?.phoneNo || '';
    this.email = this.item.consignee?.email || '';
  },

  methods: {
    getFeeAmount(type) {
      return this.item.fee[type].amount || 0;
    },

    next() {
      this.attemptSubmit = true;
      this.error = null;
      const isInputValid = this.isInputValid();

      if (!this.isAccepted) {
        return;
      }

      if (isInputValid) {
        this.isLoading = true;

        const data = {
          request: {
            phoneNo: this.formattedPhoneNumber,
            isInvoice: this.isInvoiceRequested,
          },
          item: {
            id: this.item.itemId,
            code: this.item.code,
          },
        };

        if (this.email !== '') {
          data.request.email = this.email;
        }

        this.$store.dispatch('shipment/sendCustomsDeclaration', data)
          .then(() => {
            this.isLoading = false;
            this.handleContinue();
          })
          .catch((err) => {
            this.isLoading = false;
            this.error = err;
            console.error(err);
          });
      }
    },

    isInputValid() {
      this.isPhoneNumberValid = this.validatePhoneNumber();
      this.isEmailValid = this.validateEmail();

      if (this.email) {
        return this.isPhoneNumberValid && this.isEmailValid;
      }
      return this.isPhoneNumberValid;
    },

    validatePhoneNumber() {
      if (this.phoneNumber.length > 8) {
        const parsedPhoneNumber = phoneUtil.parseAndKeepRawInput(this.phoneNumber, 'SE');
        this.formattedPhoneNumber = phoneUtil.format(parsedPhoneNumber, PNF.E164);
        return phoneUtil.isValidNumber(parsedPhoneNumber);
      }
      return false;
    },

    validateEmail() {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(this.email).toLowerCase());
    },

    resetAttemptSubmit() {
      if (this.attemptSubmit === true) {
        this.attemptSubmit = false;
      }
    },

    openModal() {
      this.isImagesModalOpen = true;
    },

    handleContinue() {
      this.$router.push({ name: 'customs-declaration', query: this.$route.query });
    },

    getPhoneErrorText() {
      if (this.attemptSubmit) {
        if (!this.phoneNumber) {
          return 'PHONE_NUMBER_REQUIRED';
        }

        if (!this.isPhoneNumberValid) {
          return 'PHONE_NUMBER_INVALID';
        }
      }

      return '';
    },
  },
};
</script>

<template>
  <div class="container customs">
    <h1>{{$t('YOUR_PARCEL')}}</h1>
    <p>{{ $t('CUSTOMS_DECLARATION_INFO', { consignor: item.consignor.name }) }}</p>

    <payment-summary-table
      :is-customs="true"
      :is-cost-preliminary="true"
      :header="$t('SHIPMENT_ID')"
      :reference="item.itemId"
      :sections="fees"
      :total="item.fee.totalFee"
      v-if="item"
    />

    <p v-if="item" v-html="$t('CUSTOMS_PRELIMINARY_FEE')" class="customs-info fee" />

    <section v-if="item.images && item.images.length > 0">
      <div class="shipment-actions">
        <a
          class="link"
          @click="openModal()"
        >{{ $t('SHOW_IMAGES_OF_PARCEL') }}</a>
      </div>
    </section>

    <form class="customs-form">
      <p>{{ $t('CUSTOMS_PAYMENT_METHODS_INFO') }}</p>
      <div class="checkbox-wrapper">
        <pn-checkbox
          checkboxid="company-invoice"
          name="company-invoice"
          value="company-invoice"
          @change="isInvoiceRequested = $event.target.checked"
          :checked="isInvoiceRequested"
        />
        <label for="company-invoice">
          {{ $t('CUSTOMS_COMPANY') }}
        </label>
        <p class="customs-info invoice">{{ $t('INVOICE_INFO') }}</p>
      </div>
      <p class="contact-info-text">{{ $t('CUSTOMS_INPUT_DESCRIPTION') }}</p>
      <pn-input
        class='pn-vat-input'
        :label="$t('PHONE_NUMBER')"
        :error="getPhoneErrorText() ? $t(getPhoneErrorText()) : ''"
        type="tel"
        autocomplete="tel"
        :value="phoneNumber"
        @input="phoneNumber = $event.target.value"
        @keyup="resetAttemptSubmit"
      />
      <pn-input
        class='pn-vat-input'
        :label="$t('EMAIL_OPTIONAL')"
        :error="attemptSubmit && !isEmailValid && !!email ? $t('EMAIL_INVALID') : ''"
        type="email"
        autocomplete="email"
        :value="email"
        @input="email = $event.target.value"
        @keyup="resetAttemptSubmit"
      />

      <div class="checkbox-wrapper">
        <pn-checkbox
          checkboxid="accept"
          name="accept"
          value="accept"
          @change="isAccepted = $event.target.checked"
          :checked="isAccepted"
        />
        <label for="accept" class="accept-text">{{ $t('CUSTOMS_ACCEPT_REPRESENTATIVE') }}</label>
        <p v-if="!isAccepted && attemptSubmit" class="error-text">{{ $t('CUSTOMS_ACCEPT_VALIDATION_ERROR') }}</p>
      </div>
      <pn-button
        class='pn-vat-button'
        :loading="isLoading"
        @click="next"
      >
        {{ $t('NEXT') }}
      </pn-button>
      <p class="error-text" v-show="error">{{ $t('ACCEPT_ERROR') }}</p>
      <a class="customs-representative" :href="$t('CUSTOMS_REPRESENTATIVE_LINK')" target="_blank" rel='noopener noreferrer'>{{ $t('CUSTOMS_REPRESENTATIVE_LINK_TEXT') }}</a>
    </form>

    <a class="read-more" :href="$t('CUSTOMS_READ_MORE_LINK')" target="_blank" rel='noopener noreferrer'>{{ $t('CUSTOMS_READ_MORE_LINK_TEXT') }}</a>
    <p class="customs-info">{{ $t('CUSTOMS_PROCESS_INFO') }}</p>

    <pn-modal
      class='pn-vat-modal'
      :open="this.isImagesModalOpen"
      @close="() => this.isImagesModalOpen = false"
    >
      <h1>{{$t('IMAGES_OF_PARCEL')}}</h1>
      <div class="image" v-for="(image, i) in item.images" :key="i">
        <img :src="image" alt="parcel" />
      </div>
    </pn-modal>
  </div>
</template>

<style lang="scss" scoped>
#main-view.customs {
  @media #{$phone} {
    padding: 16px 16px 0;
  }

  h1 {
    @media #{$phone} {
      padding: 0;
    }
  }
}

.payment-summary {
  margin-top: 40px;

  ::v-deep tr {
    td {
      line-height: 32px;
    }

    &:nth-last-child(2) td {
      padding: 0;
    }

    &:last-child td {
      border-color: $gray200;
    }

    .asterisk {
      display: inline;
    }
  }
}

.customs-form {
 .checkbox-wrapper {
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   margin: 12px 0 4px;

    label {
      color: $black;
    }

    pn-checkbox {
      margin-right: 4px;
    }
 }

  .contact-info-text {
    margin: 40px 0 12px;
  }

  .accept-text {
    @media #{$phone} {
      width: 80%;
    }
  }

  .error-text {
    color: $warning;
    font-size: 1.4rem;
    width: 100%;
    margin: 8px 0 0;
  }
}

a {
  display: block;

  &.read-more {
    margin: 0 0 20px;
    padding-top: 40px;
    border-top: 1px solid $gray200;
    text-decoration: underline;
  }

  &.customs-representative {
    text-align: center;
    margin: 20px 0 40px;
    text-decoration: underline;
  }
}

.customs-info {
  font-size: 1.4rem;
  color: $gray700;

  &.fee {
    margin: 18px 0 20px;
    padding-top: 8px;
    border-top: 1px solid $gray200;
  }

  &.invoice {
    margin: 4px 0 0 36px;
  }
}

.image {
  width: 100%;
  margin-bottom: 20px;
  > img {
    width: 100%;
  }
}

.shipment-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  a {
    margin-top: 1.6rem;

    &:first-of-type {
      margin-top: 0;
    }
  }
}
</style>
