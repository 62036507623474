<script>
import { mapGetters } from 'vuex';

const ENDPOINT = process.env.VUE_APP_TOPBAR_MARKETWEB_URL;

export default ({
  data() {
    return {
      ENDPOINT,
    };
  },

  computed: {
    ...mapGetters('topbar', ['market', 'language']),
  },
});
</script>

<template>
  <div id="footer-market-web" ref="footer-container">
    <template v-if="market && language">
      <pn-marketweb-sitefooter
        :endpoint="ENDPOINT"
        :market="market.toLowerCase()"
        :language="language"
        theme="dotcom"
        backgroundcolor="gray"
      />
    </template>
  </div>
</template>

<style lang="scss">
</style>
