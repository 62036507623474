<script>
import { mapGetters } from 'vuex';

export default ({
  props: {
    value: {
      type: Object,
      required: true,
    },
    totalFee: {
      type: Object,
      required: true,
    },
    paidAt: {
      type: String,
    },
    sections: {
      type: Array,
      required: true,
    },
    isCustoms: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters('topbar', ['market']),
    paidDate() {
      const locale = this.market === 'DK' ? 'da-DK' : 'sv-SE';
      return this.paidAt ? new Date(this.paidAt.replace(/\s+/g, 'T')).toLocaleDateString(locale) : '';
    },
  },
  methods: {
    format(val) {
      return `${val.toFixed(2).replace('.', ',')} ${this.totalFee.currency}`;
    },

    sectionTotal(section) {
      if (section.name === 'DECLARATION_FEE') {
        return `${section.rows[2].amount} ${this.totalFee.currency} ${this.$t('INCLUDING_VAT', { vat: `${section.rows[1].amount} ${this.totalFee.currency}` })}`;
      }

      if (this.market === 'DK') {
        return this.format(section.rows.reduce((sum, row) => sum + row.amount, 0));
      }

      return this.format(section.rows.reduce((sum, row) => sum + parseFloat(row.amount.toString().replace(' ', '').replace(',', '.')), 0));
    },
  },
});

</script>

<template>
  <div class="payment-summary">
    <div class="payment-header">
      <div class="row">
        <p>{{ $t('SHIPMENT_ID') }}</p>
        <p v-if="market === 'SE'">{{ value.itemId }}</p>
        <p v-if="market === 'DK'">{{ value.internationalBarCode}}</p>
      </div>
      <div class="row" v-if="isCustoms">
        <p>{{ $t('CUSTOMS_ID') }}</p>
        <p>{{ value.customsId }}</p>
      </div>
    </div>

    <div class="row">
      <p>{{ $t('PAID_DATE') }}</p>
      <p>{{ paidDate || 'N/A' }}</p>
    </div>

    <div class="row">
      <p>{{ $t('PAYMENT_METHOD') }}</p>
      <p>{{ $t(value.paymentMethod) || 'N/A' }}</p>
    </div>

    <div class="row">
      <p>{{ $t('ORDER_NUMBER') }}</p>
      <p>{{ value.cartId || value.orderId || 'N/A' }}</p>
    </div>

    <div v-for="(section, i) in sections" :key="i" class="row">
      <p>{{ $t(section.name) }}</p>
      <p>{{ sectionTotal(section) }}</p>
    </div>

    <div class="payment-sum row">
      <p>{{ $t('AMOUNT_PAYED') }}</p>
      <p class="total">
        <span>{{totalFee.amount}}</span>
        {{ totalFee.currency }}</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .payment-summary {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 4.0rem;

    .payment-header {
      border-bottom: 2px solid $blue700;
      margin-bottom: 1.6rem;
      height: auto;
    }

    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @media #{$phone} {
        &:nth-of-type(7) {
          p:last-of-type {
            max-width: 175px;
          }
        }
      }

      p {
        line-height: 1.5;

        &:first-of-type {
          flex: 0 50%;
        }

        &:last-of-type {
          text-align: right;
        }
      }
    }

    .payment-header,
    .payment-sum {
      p:first-of-type {
        font-weight: $bold;
      }
    }

    .payment-sum {
      align-items: baseline;
      border-top: 1px solid $gray50;
      padding-top: 1.6rem;
      margin: 1.6rem 0 0;

      p {
        margin: 0;
      }

      .total {
        font-size: 2em;

        > span {
          font-weight: $bold;
        }
      }
    }
  }
</style>
