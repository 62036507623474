<script>
import { mapGetters } from 'vuex';

export default ({
  name: 'CustomsDeclaration',

  data() {
    return {
      timeout: null,
    };
  },

  computed: {
    ...mapGetters('shipment', [
      'item',
    ]),
  },

  watch: {
    item: {
      deep: true,
      handler() {
        if (this.item.status === 'OPEN') {
          this.handleContinue();
        } else if (this.item.status === 'CUSTOMS_DELAYED') {
          this.handleDelay();
        }
      },
    },
  },

  mounted() {
    this.getItemStatus();
  },

  beforeDestroy() {
    clearTimeout(this.timeout);
  },

  methods: {
    getItemStatus() {
      this.timeout = setTimeout(() => {
        this.$store
          .dispatch('shipment/getV2', {
            itemId: this.item.itemId,
            code: this.item.code,
          })
          .then((data) => {
            const item = data.item || data;
            if (item.status !== 'OPEN' && item.status !== 'CUSTOMS_DELAYED') {
              this.getItemStatus();
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }, 7500);
    },

    handleContinue() {
      this.$router.push({ name: 'payment', query: this.$route.query });
    },

    handleDelay() {
      this.$router.push({ name: 'customs-declaration-delayed', query: this.$route.query });
    },
  },
});
</script>

<template>
  <div class="container">
    <h1>{{ $t('CUSTOMS_DECLARATION_INITIATED_HEADER') }}</h1>
    <p> {{ $t('CUSTOMS_DECLARATION_INITIATED_TEXT') }} </p>
    <p> {{ $t('CUSTOMS_DECLARATION_CREDIT_CUSTOMER') }} </p>
  </div>
</template>

<style lang="scss" scoped>
p {
  margin-bottom: 20px;

  @media #{$phone} {
    padding: 0 16px;
  }
}
</style>
