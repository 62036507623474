<script>
export default ({
  name: 'Invoice',

});
</script>

<template>
  <div class="container">
    <h1> {{ $t('INVOICE_HEADER') }} </h1>
    <p> {{ $t('INVOICE_TEXT') }} </p>
  </div>
</template>

<style lang="scss" scoped>
p {
  margin-bottom: 20px;

  @media #{$phone} {
    padding: 0 16px;
  }
}
</style>
