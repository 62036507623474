<script>
import { mapGetters } from 'vuex';
import Axios from 'axios';
import googleLibphonenumber from 'google-libphonenumber';
import Loader from '@/components/Loader.vue';

const phoneUtil = googleLibphonenumber.PhoneNumberUtil.getInstance();

export default {
  props: {
    value: {
      type: Object,
      market: String,
    },
    attemptSubmit: {
      type: Boolean,
    },
    id: {
      type: String,
      required: false,
      default: '',
    },
    code: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      availablePaymentMethods: [],
      shouldShowPaymentMethods: false,
      selectedMethod: {
        id: null,
        mobileNumber: '',
        shouldInitApplePay: false,
        initiatedApplePay: false,
        includeApplePayInMethodsList: null,
        applePayConfig: {},
      },
      options: {
        'SWISH-ECOMMERCE': {
          name: 'Swish',
          sub: {
            'ecommerce': 'swish.png',
          },
        },
        'CARD': {
          name: this.$t('CARD-PAYMENT'),
          sub: {
            'Visa': 'icon-visa.svg',
            'MasterCard': 'icon-mastercard.svg',
            'Maestro': 'icon-maestro.svg',
            'Dankort': 'icon-dankort.svg',
            'UNKOWN': 'icon-jcb.svg',
          },
        },
        'MOBILE-PAY': {
          name: 'MobilePay',
          sub: {
            'Mobilepay': 'icon-pay-mobilepay@2x.png',
          },
        },
        'INVOICE': {
          name: this.$t('INVOICE'),
          sub: {
            'invoice': 'icon-invoice.svg',
          },
        },
        'APPLE-PAY': {
          name: 'Apple Pay',
          sub: {
            'APPLE-PAY': 'icon-apple-pay.svg',
          },
        },
      },
    };
  },

  created() {
    if (this.market === 'SE') {
      this.getPaymentMethodsV2();
    } else {
      this.getPaymentMethods();
    }
  },

  watch: {
    selectedMethod: {
      handler(val) {
        if (val.shouldInitApplePay === true && val.initiatedApplePay) {
          if (val.includeApplePayInMethodsList === false) {
            this.availablePaymentMethods = this.availablePaymentMethods.filter((pm) => pm.method !== 'APPLE-PAY');
          }
          this.shouldShowPaymentMethods = true;
        }
        this.$emit('input', val);
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters('topbar', ['market']),
  },

  methods: {
    getPaymentMethods() {
      const url = `${process.env.VUE_APP_API_BASE_URL}/carts/paymentmethods?v=2`;

      Axios.get(url).then((response) => {
        this.filterPaymentMethods(response.data);
      });
    },

    getPaymentMethodsV2() {
      const url = `${process.env.VUE_APP_API_V2_BASE_URL}/items/${this.id}/${this.code}/payment-methods`;

      Axios.get(url).then((response) => {
        this.filterPaymentMethods(response.data);
      });
    },

    filterPaymentMethods(paymentMethods) {
      this.availablePaymentMethods = paymentMethods
        .filter((paymentMethod) => this.options[paymentMethod.method])
        .filter((paymentMethod) => paymentMethod.active === true);

      const applePayPaymentMethod = this.availablePaymentMethods.find((pm) => pm.method === 'APPLE-PAY');

      if (applePayPaymentMethod) {
        this.selectedMethod.shouldInitApplePay = true;
        this.selectedMethod.applePayConfig.paymentProviderConfiguration = applePayPaymentMethod.paymentProviderConfiguration;
      } else {
        this.shouldShowPaymentMethods = true;
      }
    },

    getName(id) {
      return this.options[id] ? this.options[id].name : null;
    },

    getLogo(id, subId) {
      return this.options[id].sub[subId];
    },

    isSelectedMethod(method) {
      return this.selectedMethod.id === method;
    },

    isMobileMethod(method) {
      return ['SWISH-ECOMMERCE', 'MOBILE-PAY'].indexOf(method) > -1;
    },

    getPhoneErrorText(number) {
      if (!this.attemptSubmit) {
        return '';
      }

      if (!number) {
        return 'PHONE_NUMBER_REQUIRED';
      }

      if (number.length < 8) {
        return 'PHONE_NUMBER_ERROR';
      }

      const phoneNumber = phoneUtil.parseAndKeepRawInput(number, 'SE');

      return !phoneUtil.isValidNumber(phoneNumber) || !phoneUtil.getNumberType(phoneNumber) === 'MOBILE' ? 'PHONE_NUMBER_ERROR' : '';
    },
  },
  components: {
    Loader,
  },
};
</script>

<template>
  <div>
    <h3>{{ $t('PAY_WITH') }}</h3>
    <template v-if="shouldShowPaymentMethods && availablePaymentMethods.length > 0">
      <div
        class="method"
        v-for="(paymentMethod, i) in availablePaymentMethods"
        :class="{ swish: (paymentMethod.method === 'SWISH-ECOMMERCE' || paymentMethod.method === 'MOBILE-PAY') && (selectedMethod.id === 'SWISH-ECOMMERCE' || selectedMethod.id === 'MOBILE-PAY') }"
        @click="selectedMethod.id = paymentMethod.method"
        :key="i">
        <template v-if="getName(paymentMethod.method)">
          <div>
            <div class="radio">
              <input
                :id="paymentMethod.method"
                class="radio-button"
                type="radio"
                :checked="selectedMethod.id === paymentMethod.method"
              />
            </div>
            <label :for="paymentMethod.method">{{ getName(paymentMethod.method) }}</label>
          </div>

          <div class="logos">
            <div v-for="(sub, i) in paymentMethod.subMethods" :key="i">
              <img
                v-if="getLogo(paymentMethod.method, sub)"
                :src="require(`../assets/images/${getLogo(paymentMethod.method, sub)}`)"
                height="20px"
                alt=""
              />
            </div>
          </div>

          <div class="mobile-input" v-if="isSelectedMethod('SWISH-ECOMMERCE') && isMobileMethod(paymentMethod.method)">
            <pn-input
              class='pn-vat-input'
              :label="$t('MOBILE_NUMBER')"
              :value="selectedMethod.mobileNumber"
              @input="selectedMethod.mobileNumber = $event.target.value"
              :error="getPhoneErrorText(selectedMethod.mobileNumber) ? $t(getPhoneErrorText(selectedMethod.mobileNumber)) : ''"
              type="tel"
              autocomplete="tel"
            />
          </div>
        </template>
      </div>
    </template>
    <template v-else-if="shouldShowPaymentMethods && availablePaymentMethods.length === 0">
      <p>{{ $t('NO_PAYMENT_METHODS_AVAILABLE') }}</p>
    </template>
    <loader v-else />
  </div>

</template>

<style lang="scss" scoped>
  h3 {

    @media #{$phone} {
      margin: 0 0 .8rem;
    }
  }

  .mobile-input {
    width: 100%;
    flex-basis: 100%;
    padding: 1.6rem 1.6rem 0;
    flex-direction: column;
    align-items: flex-start;

    pn-input {
      margin: 0;
    }

    > p {
      display: block;
      color: $warning;
      margin: 10px 0 0;
      font-size: 14px;
    }
  }

  div.method {
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 1px solid $gray50;
    padding: 16px 0;

    &:last-child {
      border-bottom: none;
      padding: 16px 0 0;
    }

    & > div {
      display: flex;

      & label {
        cursor: pointer;
      }

      > div.radio {
        width: 40px;
        position: relative;
        display: inherit;
        align-items: inherit;

        > .radio-button {
          cursor: pointer;
          width: 24px;
          height: 24px;
          appearance: none;
          position: relative;
          -webkit-appearance: none;
          border: solid 1px $gray200;
          border-radius: 50%;
          padding: 0;
          margin: 0;
          background-size: 16px 16px;
          background-position: center center;
          background-repeat: no-repeat;

          &:checked {
            &::after {
              content: '';
              position: absolute;
              border-radius: 50%;
              top: calc(50% - 7px);
              left: calc(50% - 7px);;
              width: 14px;
              height: 14px;
              transition: all .28s ease-in-out;
              background-color: $blue700;
            }
          }
        }
      }
    }

    .logos {
      div {
        display: inherit;

        img {
          margin-left: 5px;
        }
      }
    }
  }
</style>
